import { useState, useEffect, useContext } from "react";
import WeightEstimation from "components/weight-estimation";
import RadioGroup from "components/radio-group";
// import WeightInput from "components/weight-input";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { useLocation, useOutletContext } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import { localizeDecimalSeparator, getQueryParams } from "lib/utils";
import { calculateEstimate } from "lib/calculateEstimate";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useTranslation } from "react-i18next";
// import weightRestrictions from "data/weight-restrictions.json";
// import InputValidation from "components/input-validation";
import { useCurrentLocation } from "lib/utils";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";

export default function PayoutMethodPage() {
  const [weightInput, setWeightInput] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  // const [weightAlarm, setWeightAlarm] = useState(false);

  // translation
  const { t } = useTranslation();

  //get auth
  const { user } = useContext(AuthContext);

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME],
    t("urls.trade_in_weight")
  );

  const { location: currentLocation } = useCurrentLocation();

  // Reset right side visual
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    if (queryParams[TAKEBACK_METHOD_NAME] === "mail") {
      setSidebarVisual("suitcase");
    } else {
      setSidebarVisual("suitcaseStore");
    }
  }, [queryParams, setSidebarVisual]);

  // Initialise input with query param
  useEffect(() => {
    if (queryParams.w) {
      setWeightInput(queryParams.w);
    }
    if (queryParams.p) {
      setSelectedOption(queryParams.p);
    }
  }, [queryParams.w, queryParams.p]);

  const unit = t("scale.weight");

  //handle input change and max weight - commented out for now

  // const maxWeight = weightRestrictions.find(
  //   (weight) => weight.unit === unit
  // ).max;
  // const minWeight = weightRestrictions.find(
  //   (weight) => weight.unit === unit
  // ).minMail;

  // const handleInputChange = (event) => {
  //   const newValue = event.target.value;
  //   setWeightInput(newValue);
  //   if (maxWeight && newValue > maxWeight) {
  //     setWeightAlarm(
  //       t("ui.weight_alarm_max", {
  //         maxWeight: maxWeight,
  //         unit: unit,
  //       })
  //     );
  //   } else if (
  //     minWeight &&
  //     newValue < minWeight &&
  //     currentLocation?.methods.includes("mail") &&
  //     currentLocation?.methods.length === 1
  //   ) {
  //     setWeightAlarm(
  //       t("ui.weight_alarm_min", {
  //         minWeight: minWeight,
  //         unit: unit,
  //       })
  //     );
  //   } else {
  //     setWeightAlarm(null);
  //   }
  // };

  const parsedWeight = parseFloat(weightInput).toFixed(1);
  const weightEstimate =
    currentLocation?.locale !== "en-US"
      ? parsedWeight.toString().replace(".", ",")
      : parsedWeight;

  const estimate = calculateEstimate(weightInput, currentLocation?.country);
  const displayEstimate =
    currentLocation?.locale !== "en-US"
      ? estimate.gift.toString().replace(".", ",")
      : estimate.gift;

  // Generate updated params
  const updatedParams = { ...queryParams, w: weightInput, p: selectedOption };

  // conditional submit destination
  const destination =
    selectedOption !== "finish"
      ? user
        ? queryParams[TAKEBACK_METHOD_NAME] === "mail"
          ? `${t("urls.trade_in_terms")}`
          : `${t("urls.trade_in_summary")}`
        : `${t("urls.trade_in_email")}`
      : "/";

  //only show cash payment in appropriate environments
  const showPayment = useWhen(PAYMENT_ARGS);

  const radioOptions = [];
  if (currentLocation?.takebackDisbursement.includes("giftcard")) {
    radioOptions.push({
      value: "giftcard",
      title: t("trade-in.payout.giftcard_title", {
        gift: displayEstimate,
      }),
      subtitle: t("trade-in.payout.giftcard_subtitle"),
    });
  }
  if (
    showPayment &&
    (currentLocation?.takebackDisbursement.includes("tango") ||
      currentLocation?.takebackDisbursement.includes("payment") ||
      currentLocation?.takebackDisbursement.includes("payall"))
  ) {
    radioOptions.push({
      value: "payment",
      title: t("trade-in.payout.payment_title", {
        payment: localizeDecimalSeparator(estimate.payment, currentLocation),
      }),
      subtitle: t("trade-in.payout.payment_subtitle"),
    });
  }
  radioOptions.push({
    value: "finish",
    title: t("trade-in.payout.finish_title"),
    subtitle: t("trade-in.payout.finish_subtitle"),
  });

  return (
    <FormContainer
      submitLabel={
        selectedOption !== "finish"
          ? t("ui.button_continue")
          : t("ui.button_finish")
      }
      submitHref={destination}
      submitParams={updatedParams}
      submitDisabled={!selectedOption}
      // submitDisabled={!selectedOption || !weightInput || weightAlarm}
      secondaryLabel={t("ui.button_back")}
      secondaryHref={`${t("urls.trade_in_checklist")}`}
      secondaryParams={queryParams}
    >
      <ProgressBar step={4} />
      <h2 className="font-semibold text-6xl max-w-lg">
        {t("trade-in.payout.title")}
      </h2>
      <WeightEstimation
        className=""
        locale={currentLocation?.locale}
        singleValue={
          !showPayment
            ? localizeDecimalSeparator(estimate.gift, currentLocation)
            : null
        }
        range={
          showPayment
            ? [
                localizeDecimalSeparator(estimate.payment, currentLocation),
                localizeDecimalSeparator(estimate.gift, currentLocation),
              ]
            : null
        }
        description={t("ui.estimate_part2", {
          weight: weightEstimate,
          unit: unit,
        })}
        animate
        title={t("trade-in.payout.estimation_title")}
      />
      <div className="mt-4">
        <div>{t("trade-in.payout.select_option")}</div>
        <RadioGroup
          options={radioOptions}
          name="custom-radio"
          selectedOption={selectedOption}
          onChange={setSelectedOption}
          className="mt-2"
        />
      </div>
    </FormContainer>
  );
}
