import { useState } from "react";
// import LoadingGIF from "assets/loading.gif";
import BounceLoader from "react-spinners/BounceLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function LoadingSpinner({ isLoading = "Loading..." }) {
  let [color] = useState("#ffc399");

  return (
    <div className="flex flex-col gap-8 justify-center items-center h-[calc(100vh-81px)]">
      <BounceLoader
        color={color}
        loading={isLoading}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      {/* <img src={LoadingGIF} alt="" className="w-[72px]" /> */}
      {/* <h1 className="font-semibold text-6xl">{isLoading}</h1> */}
    </div>
  );
}
