import React, { useEffect, useMemo, useCallback } from "react";
import { format } from "date-fns";
import { useParams, useNavigate } from "react-router-dom";
import DataTable from "components/data-table";
import { useTakebacksByEmail } from "api/use-takebacks";
import { useTranslation } from "react-i18next";
import { calculateEstimate } from "lib/calculateEstimate";

export default function CheckInTakebacks() {
  const { email } = useParams();
  const data = useTakebacksByEmail({ email });
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleRowClick = useCallback(
    (sys_id) => {
      navigate(
        `${t("urls.store_trade_in_start")}?id=${sys_id}&e=${encodeURIComponent(
          email
        )}`
      );
    },
    [navigate, t, email]
  );

  const filteredData = useMemo(() => {
    return data
      ? data.filter(
          ({ u_lego_return_type: returnType }) => returnType !== "mail"
        )
      : [];
  }, [data]);

  const formattedData = useMemo(() => {
    return filteredData.map(
      ({ number, sys_id, sys_created_on, u_lego_presort_weight: weight }) => {
        const currentWeight = weight;

        const estimate = calculateEstimate(currentWeight, i18n.language.slice(-2))

        return {
          id: number,
          status: `Trade In Created`,
          dateCreated: sys_created_on
            ? format(new Date(sys_created_on), `MM/dd/yy HH:mm`)
            : "",
          quantity: currentWeight,
          value: `${t(`currency.symbol`)}${estimate.gift} `,
          onClick: () => handleRowClick(sys_id),
        };
      }
    );
  }, [filteredData, handleRowClick, t, i18n]);

  useEffect(() => {
    if (formattedData.length === 1) {
      formattedData[0].onClick();
    }
  }, [formattedData]);

  if (!data) {
    return null;
  }

  return (
    <div className="flex flex-col px-6 py-4 items-left h-[calc(100vh-81px)]">
      <div className="flex flex-col align-left mb-6">
        <div>
          <h1 className="text-6xl pb-2 font-bold">Customer</h1>
          <span className="text-[#7E7D7D] text-3xl font-normal">{email}</span>
        </div>
        <div className="flex flex-col w-full mt-10">
          <h1 className="text-3xl font-bold">
            {formattedData.length > 1 ? "Multiple trade-ins found" : "Trade-in"}
          </h1>
          <DataTable data={formattedData} />
        </div>
      </div>
    </div>
  );
}
