import { useContext } from "react";
import { Button } from "components/button";
import { Header } from "components/header";
import AuthContext from "contexts/AuthContext";
import { Trans, useTranslation } from "react-i18next";
import { LOCALE_NAME, STATE_NAME, COUNTRY_NAME } from "consts/query-params";
import { useCurrentLocation } from "lib/utils";
import TermsText from "../../components/terms-text";
import ScrollToTop from "components/scroll-to-top";
import { Footer } from "components/footer/footer";
// import ImpressumFooter from "components/footer-impressum";

export default function TermsConditionsPage() {
  //auth
  const { user } = useContext(AuthContext);

  //translation
  const { t } = useTranslation();

  // Get location settings
  const { location: currentLocation } = useCurrentLocation();

  const CtaButton = ({ className }) => {
    return (
      <Button
        href={`${t("urls.trade_in_weight")}`}
        queryParams={{
          [LOCALE_NAME]: currentLocation.locale,
          [STATE_NAME]: currentLocation.state,
          [COUNTRY_NAME]: currentLocation.country,
        }}
        className={className}
      >
        {t("landing.button_start_new")}
      </Button>
    );
  };

  return (
    <div className="min-h-screen w-screen flex flex-col justify-between">
      <Header classNames="bg-yellow border-none">
        <div className="flex gap-large">
          <CtaButton className="hidden md:block" />
          <Button
            href={user ? `${t("urls.my_trade_ins")}` : `${t("urls.login")}`}
            variant="secondary"
          >
            {user
              ? t("landing.button_my_trade_ins")
              : t("landing.button_sign_in")}
          </Button>
        </div>
      </Header>
      <ScrollToTop />
      <div className="container mt-16 mb-16 max-w-prose mx-auto ">
        <h1 className="text-3xl font-bold mb-8">
          <Trans i18nKey="terms.title" />
        </h1>
        <TermsText scroll={true} />
      </div>
      <Footer />
      {/* <ImpressumFooter /> */}
    </div>
  );
}
