import { cn } from "lib/utils";
import React from "react";

export default function KioskStartButton({
  children,
  color = "white",
  className,
  onClick = () => {},
  disabled = false,
  icon = "close",
  iconShow = true,
  iconColor = "[#757575]",
}) {
  return (
    <div
      className={cn(
        `bg-${color} text-[48px] px-[24px] shadow-lg py-[36px] font-normal rounded-full flex gap-[24px] items-center justify-center cursor-pointer text-center active:scale-95 active:bg-[#f4f4f4] transition transition-1 whitespace-nowrap ${
          disabled && "bg-[#FDF5D9] text-[#C8C8C8]"
        }`,
        className
      )}
      onClick={!disabled ? onClick : () => {}}
    >
      {iconShow && (
        <span
          className={`material-symbols-outlined ${
            disabled ? "text-[#C8C8C8]" : `text-${iconColor}`
          } align-bottom block`}
          style={{ fontSize: "48px", fontWeight: "800" }}
        >
          {icon}
        </span>
      )}{" "}
      {children}
    </div>
  );
}
