import { useState, useEffect } from "react";

const useSessionTakebacks = () => {
  const [takebacks, setTakebacks] = useState(() => {
    try {
      const storedTakebacks = localStorage.getItem("takebacks");
      return storedTakebacks ? JSON.parse(storedTakebacks) : [];
    } catch (error) {
      console.error("Error loading takebacks from localStorage", error);
      return [];
    }
  });

  const addTakeback = (newTakeback, onSuccess) => {
    try {
      const updatedTakebacks = [...takebacks, newTakeback];
      setTakebacks(updatedTakebacks);
      localStorage.setItem("takebacks", JSON.stringify(updatedTakebacks));

      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
    } catch (error) {
      console.error("Error adding takeback to localStorage", error);
    }
  };

  const getLastTakeback = () => {
    return takebacks.length > 0 ? takebacks[takebacks.length - 1] : null;
  };

  const updateAllTakebacks = (updateObj) => {
    try {
      const updatedTakebacks = takebacks.map((takeback) =>
        // Merge existing takeback object with the new update object
        ({ ...takeback, ...updateObj })
      );

      setTakebacks(updatedTakebacks);
      localStorage.setItem("takebacks", JSON.stringify(updatedTakebacks));
    } catch (error) {
      console.error("Error updating all takebacks in localStorage", error);
    }
  };

  const clearTakebacks = () => {
    try {
      setTakebacks([]);
      localStorage.removeItem("takebacks");
    } catch (error) {
      console.error("Error clearing takebacks from localStorage", error);
    }
  };

  // Sync state with localStorage changes (on different tabs or windows)
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "takebacks") {
        try {
          const storedTakebacks = event.newValue;
          setTakebacks(storedTakebacks ? JSON.parse(storedTakebacks) : []);
        } catch (error) {
          console.error("Error syncing takebacks with localStorage", error);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return {
    takebacks,
    addTakeback,
    getLastTakeback,
    updateAllTakebacks,
    clearTakebacks,
  };
};

export default useSessionTakebacks;
