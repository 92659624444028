import React, { useEffect, useState } from "react";
import KioskStartButton from "../components/kiosk-button";
import LegoMan from "../assets/legoMan.png";
import ArrowBack from "../assets/arrow-back.svg";

export default function Start({
  loggedWeights,
  setScreenIndex,
  setLoggedWeights,
}) {
  const [prevCustomerLoggedWeights, setprevCustomerLoggedWeights] = useState(
    []
  );

  //reset logged weights but keep a copy in case user navigates back
  useEffect(() => {
    if (loggedWeights.length > 0) {
      setprevCustomerLoggedWeights(loggedWeights);
      setLoggedWeights([]);
    }
  }, [loggedWeights, setLoggedWeights]);

  return (
    <div className="flex flex-col items-center w-full h-full px-[4vh]">
      <div className="fixed flex top-[3vh] px-[3vh] justify-between w-full">
        <KioskStartButton
          className="bg-[#0000000c] text-[48px] background rounded-full z-40"
          onClick={() => {
            if (prevCustomerLoggedWeights.length !== 0) {
              setLoggedWeights(prevCustomerLoggedWeights);
              setScreenIndex(6);
            }
          }}
        >
          <img src={ArrowBack} alt="Arrow icon" className="w-[2vh]" />
        </KioskStartButton>
        <KioskStartButton
          className="rounded-full text-[48px]"
          onClick={() => setScreenIndex(1)}
        >
          Start
        </KioskStartButton>
      </div>
      {/* <div className="mr-auto mb-auto">
      <button
        onClick={() => setScreenIndex(0)}
        type="button"
        class="bg-gray-300 rounded-full p-2 inline-flex items-center justify-center text-[#000000] focus:outline-none focus:ring-2 focus:ring-inset"
      >
        <span class="sr-only">Close menu</span>
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div> */}
      <h1 className="text-[80px] mt-[3vh] ">LEGO® Brick Take Back</h1>
      <div className="flex flex-col gap-[0vh] items-center mt-[4vh]">
        <p className="text-[120px] leading-snug text-center max-w-[45vh] text-black-400 mb-[5vh] ">
          Trade your pre-loved LEGO bricks for a LEGO e-Gift Card or digital
          cash!
        </p>
        <p className="text-[60px] font-normal max-w-4xl w-full text-center">
          Chat with one of our brick specialists to get started.
        </p>
      </div>
      <img
        src={LegoMan}
        alt="LEGO figure"
        className="w-screen absolute bottom-0 left-0"
      />
    </div>
  );
}
