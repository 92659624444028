import React, { Fragment, useState } from "react";

// import Bricks from "../assets/bricks.svg";
import KioskStartButton from "../components/start-button";
import BrickWall from "components/brick-wall";

export default function Scale({
  setScreenIndex,
  weight,
  estimate,
  logWeight,
  inputWeight,
  logFinalWeights,
  loggedWeights,
  removeLastLoggedWeight,
  setInputWeight,
  bgColorScale,
  setBgColorScale,
}) {
  // get value between 0 and 100 depending on weight to control animation
  const wallAnimationProgress = 50;

  //cancel confirmation UI
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const sumLoggedWeights = loggedWeights.reduce(
    (total, weight) => total + weight,
    0
  );

  const lastAddedWeight = weight - sumLoggedWeights;
  const newWeight = weight - lastAddedWeight;

  const handleCancel = () => {
    if (loggedWeights.length > 0) {
      removeLastLoggedWeight();
      setInputWeight(newWeight);
    } else {
      setShowCancelConfirmation(true);
      setBgColorScale(true);
    }
  };

  const handleAddTray = () => {
    logWeight();
    setScreenIndex(4);
  };
  
  const disabledCancel = loggedWeights.length > 0;

  return (
    <div className="flex flex-col items-center justify-between w-full h-full relative">
      <div className="absolute top-0 left-0 w-full h-full z-0">
        {!showCancelConfirmation && (
          <BrickWall
            initialWeight={weight}
            speedFactor={5}
            animationProgress={wallAnimationProgress}
          />
        )}
      </div>
      <div className="flex flex-col px-[4vh] relative z-10">
        <p
          className={`${
            !showCancelConfirmation
              ? "text-[90px] text-black"
              : "text-[120px] text-white"
          } text-center max-w-[50vh] mt-[3vh] px-[142px]`}
        >
          {!showCancelConfirmation
            ? "Cha-ching! Your LEGO® stash is worth"
            : "Are you sure you want to start over?"}
        </p>
        {!showCancelConfirmation && (
          <div className="flex w-full pl-[110px] justify-center items-baseline">
            <div className="text-[252px]">{weight.toFixed(1)}</div>
            <div className="text-[69px] ml-[0.5vh]">lbs</div>
          </div>
        )}
        {!showCancelConfirmation && (
          <div className="flex w-full justify-center gap-[4vh]">
            <div className="flex flex-col items-center">
              <div className="flex items-top">
                <div className="text-[40px] mr-[1rem] mt-[1vh]">$</div>
                <div className="text-[90px]">{estimate.gift}</div>
              </div>
              <div
                style={{ fontWeight: 400 }}
                className="text-[40px] w-[16vh] text-center text-black-400"
              >
                As a LEGO e-Gift Card
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex items-top">
                <div className="text-[40px] mr-[1rem] mt-[1vh]">$</div>
                <div className="text-[90px]">{estimate.payment}</div>
              </div>
              <div
                style={{ fontWeight: 400 }}
                className="text-[40px] w-[16vh] text-center text-black-400"
              >
                As Digital Cash{" "}
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className={`grid ${
          showCancelConfirmation ? "grid-cols-2" : "grid-cols-3"
        } w-full gap-[48px] px-[4vh] relative`}
        style={{ zIndex: 1000 }}
      >
        {!showCancelConfirmation ? (
          <Fragment>
            <KioskStartButton
              className={`${disabledCancel && "text-[#C9C9C9] bg-white"}`}
              onClick={() => handleCancel()}
              disabled={disabledCancel}
            >
              Cancel
            </KioskStartButton>
            <KioskStartButton
              className=""
              onClick={() => {
                handleAddTray();
              }}
              icon="add"
            >
              <div className="text-left">Add tray</div>
            </KioskStartButton>
            <KioskStartButton
              className="font-semibold"
              onClick={() => {
                logFinalWeights();
                setScreenIndex(6);
              }}
              disabled={weight === 0}
              icon="check"
              iconColor="black"
              color="yellow"
            >
              Done
            </KioskStartButton>
          </Fragment>
        ) : (
          <Fragment>
            <KioskStartButton
              className=""
              onClick={() => {
                setScreenIndex(0);
                setBgColorScale(false);
                localStorage.removeItem("hasAnimated");
              }}
              iconShow={false}
            >
              Start over
            </KioskStartButton>
            <KioskStartButton
              className="font-bold"
              onClick={() => {
                setShowCancelConfirmation(false);
                setBgColorScale(false);
              }}
              color="yellow"
              iconColor="black"
            >
              Cancel
            </KioskStartButton>
          </Fragment>
        )}
      </div>
    </div>
  );
}
