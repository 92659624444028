export const ProfileHead = () => (
  <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.75 2.07031C5.75 1.37996 6.30964 0.820312 7 0.820312H13C13.6904 0.820312 14.25 1.37996 14.25 2.07031V3.32031H5.75V2.07031Z" fill="#FFCF00" stroke="black" strokeWidth="1.5" />
    <path d="M4.5 18.5703H15.5V20.0703C15.5 20.8987 14.8284 21.5703 14 21.5703H6C5.17157 21.5703 4.5 20.8987 4.5 20.0703V18.5703Z" fill="#FFCF00" stroke="black" />
    <rect x="0.75" y="3.82031" width="18.5" height="15.5" rx="4.25" fill="#FFCF00" stroke="black" strokeWidth="1.5" />
    <circle cx="7" cy="10.0703" r="1" fill="black" />
    <circle cx="13" cy="10.0703" r="1" fill="black" />
    <path d="M7 13.0703C7 13.0703 8 14.5703 10 14.5703C12 14.5703 13 13.0703 13 13.0703" stroke="black" strokeLinecap="round" />
  </svg>
)
