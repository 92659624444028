import "material-symbols/outlined.css";

const RadioGroup = ({ options, name, selectedOption, onChange, className }) => {
  return (
    <div className={className}>
      {options.map((option, index) => (
        <label
          key={option.value}
          className={`cursor-pointer flex border border-gray-300 px-x-large py-large ${
            index === 0 ? "rounded-t-2xl" : ""
          } ${index === options.length - 1 ? "rounded-b-2xl" : ""} ${
            index !== 0 ? "-mt-[1px]" : ""
          } ${
            selectedOption === option.value
              ? "!border-[#343b726b] relative bg-[#242e7a0d] z-10"
              : ""
          }`}
        >
          <div className="grid place-items-center cursor-pointer">
            <input
              type="radio"
              name={name}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => onChange(option.value)}
              className={`appearance-none w-6 h-6 border rounded-full shrink-0 col-start-1 row-start-1 bg-white ${
                selectedOption === option.value
                  ? "border-[#343b72]"
                  : "border-primary"
              }`}
            />
            {selectedOption === option.value && (
              <div className="w-3 h-3 rounded-full bg-[#343b72] col-start-1 row-start-1" />
            )}
          </div>
          <div className="flex items-center">
            {option.icon &&
              (option.icon === "storefront" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#757575"
                  viewBox="0 -960 960 960"
                  className={`ml-4 shrink-0 ${option.iconstyle} ${
                    selectedOption === option.value
                      ? "fill-primary"
                      : "fill-tertiary"
                  }`}
                >
                  <path d="M841-518v318q0 33-23.5 56.5T761-120H201q-33 0-56.5-23.5T121-200v-318q-23-21-35.5-54t-.5-72l42-136q8-26 28.5-43t47.5-17h556q27 0 47 16.5t29 43.5l42 136q12 39-.5 71T841-518zm-272-42q27 0 41-18.5t11-41.5l-22-140h-78v148q0 21 14 36.5t34 15.5zm-180 0q23 0 37.5-15.5T441-612v-148h-78l-22 140q-4 24 10.5 42t37.5 18zm-178 0q18 0 31.5-13t16.5-33l22-154h-78l-40 134q-6 20 6.5 43t41.5 23zm540 0q29 0 42-23t6-43l-42-134h-76l22 154q3 20 16.5 33t31.5 13zM201-200h560v-282q-5 2-6.5 2H751q-27 0-47.5-9T663-518q-18 18-41 28t-49 10q-27 0-50.5-10T481-518q-17 18-39.5 28T393-480q-29 0-52.5-10T299-518q-21 21-41.5 29.5T211-480h-4.5q-2.5 0-5.5-2v282zm560 0H201h560z"></path>
                </svg>
              ) : option.icon === "package_2" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="#757575"
                  viewBox="0 -960 960 960"
                  className={`ml-4 shrink-0 ${option.iconstyle} ${
                    selectedOption === option.value
                      ? "fill-primary"
                      : "fill-tertiary"
                  }`}
                >
                  <path d="M440-183v-274L200-596v274l240 139zm80 0l240-139v-274L520-457v274zm-80 92L160-252q-19-11-29.5-29T120-321v-318q0-22 10.5-40t29.5-29l280-161q19-11 40-11t40 11l280 161q19 11 29.5 29t10.5 40v318q0 22-10.5 40T800-252L520-91q-19 11-40 11t-40-11zm200-528l77-44-237-137-78 45 238 136zm-160 93l78-45-237-137-78 45 237 137z"></path>
                </svg>
              ) : (
                <span
                  className={`material-symbols-outlined ml-4 ${
                    option.iconstyle
                  } ${
                    selectedOption === option.value
                      ? "text-primary"
                      : "text-tertiary"
                  }`}
                >
                  {option.icon}
                </span>
              ))}
            <div className="flex flex-col ml-x-large gap-x-small">
              {option.title && (
                <p
                  className={
                    selectedOption === option.value ? "font-medium" : ""
                  }
                >
                  {option.title}
                </p>
              )}
              {option.subtitle && (
                <p className="text-tertiary text-sm">{option.subtitle}</p>
              )}
            </div>
          </div>
        </label>
      ))}
    </div>
  );
};

export default RadioGroup;
