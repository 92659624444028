import { useState, useRef, useEffect, useContext } from "react";
import SuitcaseIcon from "assets/suitcaseIcon.svg";
import "material-symbols/outlined.css";
import { calculateEstimate } from "lib/calculateEstimate";
import { useTranslation } from "react-i18next";
import AuthContext from "contexts/AuthContext";
// import ConfirmationDialog from "components/confirmation-dialog";
// import QRCode from "react-qr-code";
// import InputValidation from "components/input-validation";
import PayallButton from "components/payall-button";
import TangoButton from "components/tango-button";
// import PaymentConfirmation from "components/tango-confirmation";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";
const { format, parse } = require("date-fns");

// const { REACT_APP_API } = process.env;

// const fetchTakeback = async ({ accessToken, id, setShipping }) => {
//   try {
//     const response = await fetch(`${REACT_APP_API}/takeback/${id}`, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${accessToken}`,
//       },
//     });

//     if (!response.ok) {
//       throw new Error("Failed to fetch takeback");
//     }

//     const data = await response.json();
//     const labelBase64 =
//       data?.[0]?.u_lego_dhl_label_b64_encoded ||
//       data?.[0]?.u_ups_shipping_label_encoded;

//     if (labelBase64) {
//       setShipping(labelBase64);
//     }
//   } catch (error) {
//     console.error(error);
//   }
// };

const formatDate = ({ utcDateStr, formatStr }) => {
  const inputFormat = "yyyy-MM-dd HH:mm:ss";

  const parsedDate = parse(utcDateStr, inputFormat, new Date());

  const utcDate = new Date(
    Date.UTC(
      parsedDate.getFullYear(),
      parsedDate.getMonth(),
      parsedDate.getDate(),
      parsedDate.getHours(),
      parsedDate.getMinutes(),
      parsedDate.getSeconds()
    )
  );

  const localDate = new Date(utcDate);

  return format(localDate, formatStr);
};

export default function MyTradeInsCard({
  weight,
  createdAt,
  receivedAt,
  payoutMethod,
  returnMethod,
  verifiedAt,
  caseId,
  shipping,
  takebackIban,
  sys_id,
  takeBackCountry,
  setPayallOpen,
  issuedAt,
  u_tango_redeem_link,
}) {
  const { t, i18n } = useTranslation();
  const { user } = useContext(AuthContext);
  const currencySymbol = "$";
  const unit = "lbs";
  console.log(user);

  // const qrCodeValue = `${sys_id}`;
  // const [modalOpen, setModalOpen] = useState(false);
  // const openModal = () => setModalOpen(true);
  // const closeModal = () => setModalOpen(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  // const handleOpenModal = () => {
  //   setMenuOpen(false);
  //   openModal();
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuOpen && !event.target.closest(".relative")) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuOpen]);

  // const [pdfUrl, setPdfUrl] = useState();
  // const [shippingLabel, setShippingLabel] = useState(shipping);

  // useEffect(() => {
  //   if (!shippingLabel) {
  //     const interval = setInterval(() => {
  //       fetchTakeback({
  //         accessToken: authTokens.access_token,
  //         id: sys_id,
  //         setShipping: setShippingLabel,
  //       });
  //     }, 5000);

  //     return () => clearInterval(interval);
  //   }
  // }, [shippingLabel, sys_id, authTokens.access_token]);

  // useEffect(() => {
  //   if (shippingLabel) {
  //     const base64Data = shippingLabel;
  //     const blob = new Blob(
  //       [Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0))],
  //       { type: "application/pdf" }
  //     );
  //     const url = URL.createObjectURL(blob);
  //     setPdfUrl(url);

  //     return () => URL.revokeObjectURL(url);
  //   }
  // }, [shippingLabel]);

  // const bodyModal =
  //   returnMethod === "mail" ? (
  //     shippingLabel ? (
  //       <iframe
  //         src={pdfUrl}
  //         title={t("my_trade_ins.label_header")}
  //         className="min-w-full min-h-full"
  //       />
  //     ) : (
  //       <InputValidation
  //         errorMessage={t("trade-in.next_steps.print_warning")}
  //         fontSize="text-base"
  //         className="ml-8"
  //       />
  //     )
  //   ) : (
  //     <QRCode className="my-auto" value={qrCodeValue} size={250} />
  //   );

  //paid at is at the same as verified time if payout method is giftcard - but we need the separation for payments to display payment ui
  const paidAt = payoutMethod === "giftcard" ? verifiedAt : issuedAt;

  const stepsMail = [
    { label: t("my_trade_ins.trade_in_created"), date: createdAt },
    { label: t("my_trade_ins.package_sent"), date: null },
    { label: t("my_trade_ins.arrived"), date: receivedAt },
    { label: t("my_trade_ins.verified"), date: verifiedAt, value: "verified" },
    {
      label:
        payoutMethod === "giftcard" || takeBackCountry === "de-DE"
          ? t("my_trade_ins.payment_issued")
          : t("my_trade_ins.digital_cash_ready"),
      date: paidAt,
      value: "paid",
    },
  ];

  const stepsInStore = [
    { label: t("my_trade_ins.trade_in_created"), date: createdAt },
    { label: t("my_trade_ins.drop_in"), date: null },
    {
      label: t("my_trade_ins.verified"),
      date: verifiedAt,
      value: "verified",
    },
    {
      label:
        payoutMethod === "giftcard" || takeBackCountry === "de-DE"
          ? t("my_trade_ins.payment_issued")
          : t("my_trade_ins.digital_cash_ready"),
      date: paidAt,
      value: "paid",
    },
  ];

  console.log(createdAt);

  const steps = returnMethod === "mail" ? stepsMail : stepsInStore;

  const activeIndex = steps.reduce((latestIndex, step, index) => {
    if (!step.date) return latestIndex;
    return index;
  }, -1);

  const originCountry = "US";
  const regionCode = i18n.language.slice(-2);
  const currentWeight = weight;
  const displayWeight =
    originCountry === "US" ? weight : weight.toString().replace(".", ",");
  const estimate = calculateEstimate(currentWeight, regionCode);
  const value =
    originCountry === "US"
      ? `${currencySymbol}${
          payoutMethod === "giftcard" ? estimate.gift : estimate.payment
        } `
      : `${
          payoutMethod === "giftcard"
            ? estimate.gift.toString().replace(".", ",")
            : estimate.payment.toString().replace(".", ",")
        } ${currencySymbol} `;

  const dotRefs = useRef([]);
  const [lineHeight, setLineHeight] = useState(0);

  useEffect(() => {
    if (dotRefs.current[activeIndex]) {
      const activeDotTop = dotRefs.current[activeIndex].offsetTop;
      setLineHeight(activeDotTop);
    }
  }, [activeIndex, steps.length]);

  //check for next in domain
  const showPayment = useWhen(PAYMENT_ARGS);

  return (
    <div className="bg-white p-6 md:p-8 rounded-lg mx-auto mt-10 border border-gray-300 w-full relative">
      <div className="flex">
        <div className="w-20 pr-8 hidden md:block">
          <img src={SuitcaseIcon} alt="Lego Brick Icon" className="px-1" />
        </div>
        <div className="flex-1 flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <div className="py-1 px-2 rounded-full bg-gray-300/25 border border-gray uppercase text-xs leading-none">
              {verifiedAt
                ? t("my_trade_ins.trade_in_verified")
                : t("my_trade_ins.trade_in_created")}
            </div>
            <div className="relative">
              <button
                onClick={toggleMenu}
                className={`material-symbols-outlined text-secondary hover:bg-gray-300 p-2 rounded-full`}
              >
                more_horiz
              </button>
              {menuOpen && (
                <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    <button
                      // onClick={handleOpenModal}
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                      role="menuitem"
                    >
                      {returnMethod === "mail"
                        ? t("my_trade_ins.shipping_label")
                        : t("my_trade_ins.qr_code")}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <h2 className="text-4xl font-semibold leading-none">
            {displayWeight} {unit} {t("my_trade_ins.card_subtitle")}
          </h2>
          <div className="text-secondary">
            {value}{" "}
            {payoutMethod === "giftcard"
              ? t("my_trade_ins.card_giftcard")
              : t("my_trade_ins.card_payment")}
          </div>

          {/* mobile timeline */}
          <div className="lg:hidden relative w-full flex flex-col gap-8 my-4">
            <div
              className={`absolute left-2 top-2 ${
                activeIndex === steps.length - 1 ? "bottom-6" : "bottom-2"
              } w-1 bg-gray-300`}
            ></div>

            <div
              className="absolute left-2 w-1 bg-green"
              style={{
                top: "1rem",
                height: `${lineHeight}px`,
                transition: "height 0.3s ease",
              }}
            ></div>

            {steps.map((step, index) => (
              <div
                className="flex"
                key={index}
                ref={(el) => (dotRefs.current[index] = el)}
              >
                <div
                  className={`mt-1 w-5 h-5 rounded-full border-2 border-white z-10 ${
                    index <= activeIndex ? "bg-green" : "bg-gray-300"
                  }`}
                ></div>
                <div className={`ml-4 mt-1 text-sm`}>
                  <div
                    className={`${index === activeIndex ? "font-bold" : ""}`}
                  >
                    {step.label}
                  </div>
                  {step.date && (
                    <div className="text-tertiary">
                      {originCountry === "US"
                        ? formatDate({
                            utcDateStr: step.date,
                            formatStr: "MM/dd/yyyy",
                          })
                        : formatDate({
                            utcDateStr: step.date,
                            formatStr: "dd.MM.yyyy",
                          })}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* desktop timeline */}
          <div className="hidden lg:flex relative w-full items-start justify-between">
            <div className="absolute left-0 right-0 top-2 h-1 bg-gray-300"></div>
            <div
              className="absolute left-0 h-1 top-2 bg-green"
              style={{
                width: `${(activeIndex / (steps.length - 1)) * 100}%`,
                transition: "width 0.3s ease",
              }}
            ></div>
            {steps.map((step, index) => (
              <div
                key={index}
                className="relative flex flex-col items-center h-14"
              >
                <div
                  className={`w-5 h-5 rounded-full border-2 border-white z-10 ${
                    index <= activeIndex ? "bg-green" : "bg-gray-300"
                  }`}
                ></div>
                <div
                  className={`absolute top-6 text-sm flex flex-col  ${
                    index === 0
                      ? "text-left left-0 items-start"
                      : index === steps.length - 1
                      ? "text-right right-0 items-end"
                      : "text-center items-center"
                  }`}
                >
                  <div
                    className={`min-w-[160px] ${
                      index === activeIndex ? "font-bold" : ""
                    }`}
                  >
                    {step.label}
                  </div>
                  {step.date && (
                    <div className="text-tertiary">
                      {originCountry === "US"
                        ? formatDate({
                            utcDateStr: step.date,
                            formatStr: "MM/dd/yyyy",
                          })
                        : formatDate({
                            utcDateStr: step.date,
                            formatStr: "dd.MM.yyyy",
                          })}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* show payout options once verified */}
          {showPayment &&
            payoutMethod !== "giftcard" &&
            (steps[activeIndex].value === "verified" ||
              steps[activeIndex].value === "paid") &&
            takeBackCountry === "de-DE" && (
              <PayallButton
                ibanIsSet={Boolean(takebackIban)}
                issuedAt={issuedAt}
                className="mt-6 w-full"
                setModalOpen={setPayallOpen}
              />
            )}

          {showPayment &&
            payoutMethod !== "giftcard" &&
            steps[activeIndex].value === "verified" &&
            takeBackCountry === "en-US" && (
              <TangoButton
                caseId={caseId}
                u_tango_redeem_link={u_tango_redeem_link}
                className="mt-6 w-full"
                activeStep={steps[activeIndex].value}
              />
            )}

          {/* {modalOpen && (
            <ConfirmationDialog
              className="z-50"
              isOpen={modalOpen}
              onClose={closeModal}
              onConfirm={closeModal}
              print={returnMethod === "mail"}
              title={
                returnMethod === "mail"
                  ? t("my_trade_ins.label_header")
                  : t("my_trade_ins.qr_code")
              }
              body={bodyModal}
              primaryText={t("my_trade_ins.print_label")}
              secondaryText={t("my_trade_ins.close_modal")}
              pdfUrl={pdfUrl}
            />
          )} */}
        </div>
      </div>
    </div>
  );
}
