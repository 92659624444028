import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getQueryParams } from "lib/utils";
import { useLocation } from "react-router-dom";
import { Header } from "components/header";
import { Input } from "components/input";
import { Button } from "components/button";

const { REACT_APP_API } = process.env;

const PasswordForgot = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const [email, setEmail] = useState(``);
  const [complete, setComplete] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${REACT_APP_API}/password/forgot`,
        {
          method: `POST`,
          body: JSON.stringify({
            email,
            returnToPath: `${queryParams.returnToPath}?updatedPassword=true`,
            passwordUpdatePath: t(`urls.password_update`),
          }),
        }
      );

      if (!response.ok) {
        throw new Error();
      }

      setComplete(true);
    } catch (error) {
      console.error(error);
      alert('Please try again');
    }
  };

  return (
    <div className="min-h-screen">
      <Header />
      {complete ? (
        <>{t(`ui.password_check_email`)}</>
      ): (
        <form className="w-full flex flex-col gap-x-large" onSubmit={handleSubmit}>
          <h2 className="text-2xl font-semibold mb-6">{t(`ui.password_forgot`)}</h2>
          <Input
            label={t("ui.input_email")}
            type="email"
            onChange={event => setEmail(event.target.value)}
            value={email}
          />
          <Button type="submit">
            {t(`ui.button_continue`)}
          </Button>
        </form>
      )}
    </div>
  );
};

export default PasswordForgot;
