export const SOURCE_ID_NAME = `sourceId`;
export const LOCALE_NAME = `locale`;
export const COUNTRY_NAME = `country`;
export const STATE_NAME = `state`;
export const TAKEBACK_METHOD_NAME = `takebackMethod`;
export const TAKEBACK_DISBURSEMENT_NAME = `takebackDisbursement`;
export const TAKEBACK_WEIGHT_NAME = `takebackWeight`;
export const TAKEBACK_ID = `takebackId`;

export const QUERY_PARAMS = [
  {
    name: SOURCE_ID_NAME,
    serviceName: SOURCE_ID_NAME,
  },
  {
    name: LOCALE_NAME,
    serviceName: LOCALE_NAME,
  },
  {
    name: COUNTRY_NAME,
    serviceName: COUNTRY_NAME,
    toServiceFormatter: subject => subject.toUpperCase(),
    fromServiceFormatter: subject => subject.toLowecase(),
  },
  {
    name: STATE_NAME,
    serviceName: STATE_NAME,
  },
  {
    name: TAKEBACK_METHOD_NAME,
    serviceName: TAKEBACK_METHOD_NAME,
  },
  {
    name: TAKEBACK_DISBURSEMENT_NAME,
    serviceName: TAKEBACK_DISBURSEMENT_NAME,
  },
  {
    name: TAKEBACK_WEIGHT_NAME,
    serviceName: TAKEBACK_WEIGHT_NAME,
  },
  {
    name: TAKEBACK_ID,
    serviceName: TAKEBACK_ID,
  }
];

export const queryParamByName = (name) => {
  return QUERY_PARAMS.find((item) => item.name === name);
}
