import React from "react";
import KioskStartButton from "../components/start-button";
import FlyingBricks from "../assets/flyingBricks.svg";

export default function ClearTray({
  setScreenIndex,
  inputWeight,
  setInputWeight,
}) {
  //   useEffect(() => {
  //     if (inputWeight === 0) {
  //       setScreenIndex(4);
  //     }
  //   }, [inputWeight, setScreenIndex]);

  const handleCancel = () => {
    setInputWeight(0);
    setScreenIndex(3);
  };

  return (
    <div
      className="flex flex-col items-center justify-between w-full h-full relative"
      //   onClick={() => setScreenIndex(4)}
    >
      <p className="text-[4.5vh] text-center max-w-[50vh] text-white mt-[3vh] px-[4vh]">
        Clear the tray of LEGO® bricks
      </p>
      <div className="w-full flex flex-row justify-between px-[4vh] gap-[1vh]">
        <KioskStartButton
          className={`relative z-50 w-1/2`}
          onClick={() => handleCancel()}
        >
          Cancel
        </KioskStartButton>
        <KioskStartButton
          className="relative z-50 w-1/2 font-bold"
          onClick={() => setScreenIndex(5)}
          icon="arrow_forward"
          iconColor="black"
          color="yellow"
          disabled={inputWeight !== 0}
        >
          Continue
        </KioskStartButton>
      </div>
      <img
        src={FlyingBricks}
        alt="Simplified depiction of flying LEGO bricks"
        className="absolute bottom-[-10vh] shrink-0"
      />
    </div>
  );
}
