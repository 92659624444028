import { useMutation } from "react-query";
import { createdHtml, signUpHtml, verifiedHtml } from "consts/email-templates";
const { REACT_APP_API } = process.env;

const emails = {
  signUp: {
    subject: "Complete your sign up",
    body: ({ linkUrl, hostname }) => signUpHtml({ linkUrl, hostname }),
  },
  created: {
    subject: "Your DemoBrand Trade-In is Ready to Roll! Here's What's Next…",
    body: ({ hostname, userEmail, method }) =>
      createdHtml({ hostname, userEmail, method }),
  },
  verified: {
    subject: "We've verified your DemoBrand Suitcase! Here's What's Next…",
    body: ({ hostname, userEmail, weight, value, reward }) =>
      verifiedHtml({ hostname, userEmail, weight, value, reward }),
  },
};

const postEmail = async ({
  emailType,
  userEmail,
  emailLinkUrl = window.location.origin,
  hostname = window.location.origin,
  weight,
  value,
  reward,
  method,
}) => {
  const { email } = JSON.parse(localStorage.getItem("user")) || {
    email: userEmail,
  };

  const response = await fetch(`${REACT_APP_API}/email/send`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      to: email,
      from: "noreply@pentatonic.com",
      subject: emails[emailType]?.subject,
      body: emails[emailType]?.body({
        linkUrl: emailLinkUrl,
        hostname: hostname,
        userEmail: userEmail,
        weight: weight,
        value: value,
        reward: reward,
        method: method,
      }),
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to send email");
  }

  return response.json();
};

export const useSendEmailUpdate = () => {
  return useMutation(postEmail);
};
