import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "components/header";
import { Button } from "components/button";
import { LOCALE_NAME, STATE_NAME, COUNTRY_NAME } from "consts/query-params";
import { useCurrentLocation } from "lib/utils";
// import { Link } from "react-router-dom";
import AuthContext from "contexts/AuthContext";
import ScrollToTop from "components/scroll-to-top";
import { Footer } from "components/footer/footer";

// import ImpressumFooter from "components/footer-impressum";

const CookieDeclarationPage = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { location: currentLocation } = useCurrentLocation();

  const CtaButton = ({ className }) => {
    return (
      <Button
        href={t("urls.trade_in_weight")}
        queryParams={{
          [LOCALE_NAME]: currentLocation.locale,
          [STATE_NAME]: currentLocation.state,
          [COUNTRY_NAME]: currentLocation.country,
        }}
        className={className}
      >
        {t("landing.button_start_new")}
      </Button>
    );
  };

  const englishComponent = (
    <div className="max-w-prose mx-auto">
      <h1 className="text-3xl font-bold mb-8">Cookie Declaration</h1>

      <h1 className="mb-4">This is where your cookie declaration goes.</h1>
      <p className="text-black-300">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni
        architecto neque praesentium facilis nihil atque at dignissimos
        repudiandae esse. Suscipit eaque expedita rem esse molestias nesciunt
        voluptate illo consectetur nemo. Obcaecati repudiandae unde
        necessitatibus, rerum suscipit incidunt illo quasi iusto inventore
        officia quidem cum amet voluptates odio numquam dicta, provident beatae?
        Aliquam alias odit labore facere accusamus maiores autem mollitia!
      </p>
    </div>
  );

  // const germanComponent = (
  //   <div className="max-w-prose mx-auto">
  //     <h1 className="text-3xl font-bold mb-8">Cookie-Erklärung</h1>

  //     <p className="mb-6">
  //       <em>Zuletzt geändert: 15. Juli 2024</em>
  //     </p>

  //     <p className="mb-6">
  //       Nachfolgend finden Sie eine Liste von Cookies und ähnlichen Technologien
  //       („Cookies“), die auf der Domain LEGOBrickTakeBack.com gesetzt werden.
  //       Die Cookies werden entweder von der LEGO Group oder von ausgewählten
  //       Drittparteien gesetzt. Wenn eine Drittpartei ein gemeinsamer oder
  //       unabhängiger Verantwortlicher ist, wird dies in der Spalte
  //       „Verantwortlicher“ angegeben.
  //     </p>

  //     <p className="mb-6">
  //       Um mehr über Cookies zu erfahren, besuchen Sie bitte unsere{" "}
  //       <Link
  //         to={t("urls.cookie_policy")}
  //         className="text-blue hover:underline"
  //         target="_blank"
  //         rel="noreferrer"
  //       >
  //         Cookie-Richtlinie
  //       </Link>
  //       . Um mehr über Ihre Privatsphäre und die Kontrolle Ihrer Daten zu
  //       erfahren, besuchen Sie bitte unsere{" "}
  //       <Link
  //         to={t("urls.privacy_policy")}
  //         className="text-blue hover:underline"
  //         target="_blank"
  //         rel="noreferrer"
  //       >
  //         Datenschutzerklärung
  //       </Link>
  //       .
  //     </p>
  //   </div>
  // );

  return (
    <div className="min-h-screen w-screen flex flex-col justify-between">
      <Header classNames="bg-yellow border-none">
        <div className="flex gap-large">
          <CtaButton className="hidden md:block" />
          <Button
            href={user ? t("urls.my_trade_ins") : t("urls.login")}
            variant="secondary"
          >
            {user
              ? t("landing.button_my_trade_ins")
              : t("landing.button_sign_in")}
          </Button>
        </div>
      </Header>
      <ScrollToTop />
      <div className="w-full px-8 container mx-auto mt-16 mb-16">
        {englishComponent}

        {/* <div className="w-screen max-w-[95%] mx-auto mt-10 overflow-x-auto">
          <h2 className="text-2xl font-semibold">Cookies</h2>
          <table className="w-full mt-4 border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 p-2">
                  {i18n.language === "en-US" ? "Name" : "Name"}
                </th>
                <th className="border border-gray-300 p-2">
                  {i18n.language === "en-US" ? "Description" : "Beschreibung"}
                </th>
                <th className="border border-gray-300 p-2">
                  {i18n.language === "en-US" ? "Duration" : "Dauer"}
                </th>
                <th className="border border-gray-300 p-2">
                  {i18n.language === "en-US"
                    ? "Controller"
                    : "Verantwortlicher"}
                </th>
                <th className="border border-gray-300 p-2">
                  {i18n.language === "en-US" ? "PII Data" : "PII-Daten"}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-gray-300 p-2">
                  cookieyes-consent
                </td>
                <td className="border border-gray-300 p-2">
                  {i18n.language === "en-US"
                    ? "CookieYes sets this cookie to remember users' consent preferences so that their preferences are respected on subsequent visits to this site. It does not collect or store any personal information about the site visitors."
                    : "CookieYes setzt dieses Cookie, um die Zustimmung der Nutzer zu speichern, sodass deren Präferenzen bei späteren Besuchen dieser Seite beachtet werden. Es sammelt oder speichert keine persönlichen Informationen über die Besucher der Seite."}
                </td>
                <td className="border border-gray-300 p-2">1 year</td>
                <td className="border border-gray-300 p-2">The LEGO Group</td>
                <td className="border border-gray-300 p-2">No</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">s_cc</td>
                <td className="border border-gray-300 p-2">
                  {i18n.language === "en-US"
                    ? "Adobe Analytics: Determines if cookies are enabled in the browser."
                    : "Adobe Analytics: Bestimmt, ob Cookies im Browser aktiviert sind."}
                </td>
                <td className="border border-gray-300 p-2">Session</td>
                <td className="border border-gray-300 p-2">The LEGO Group</td>
                <td className="border border-gray-300 p-2">No</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">s_fid</td>
                <td className="border border-gray-300 p-2">
                  {i18n.language === "en-US"
                    ? "Adobe Analytics: An alternative to s_vi, used when third-party cookies are blocked or not supported."
                    : "Adobe Analytics: Eine Alternative zu s_vi, die verwendet wird, wenn Drittanbieter-Cookies blockiert oder nicht unterstützt werden."}
                </td>
                <td className="border border-gray-300 p-2">2 years</td>
                <td className="border border-gray-300 p-2">The LEGO Group</td>
                <td className="border border-gray-300 p-2">No</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">s_sq</td>
                <td className="border border-gray-300 p-2">
                  {i18n.language === "en-US"
                    ? "Adobe Analytics: Contains information about the previous link clicked within the site. It is used to track link tracking data."
                    : "Adobe Analytics: Enthält Informationen über den vorherigen Link, der auf der Website angeklickt wurde. Es wird verwendet, um Link-Tracking-Daten zu verfolgen."}
                </td>
                <td className="border border-gray-300 p-2">Session</td>
                <td className="border border-gray-300 p-2">The LEGO Group</td>
                <td className="border border-gray-300 p-2">No</td>
              </tr>
              <tr>
                <td className="border border-gray-300 p-2">s_vi_</td>
                <td className="border border-gray-300 p-2">
                  {i18n.language === "en-US"
                    ? "Adobe Analytics: A unique visitor ID cookie used to identify a unique visitor."
                    : "Adobe Analytics: Ein markantes Besucher-ID-Cookie, das verwendet wird, um einen Besucher zu identifizieren."}
                </td>
                <td className="border border-gray-300 p-2">2 years</td>
                <td className="border border-gray-300 p-2">The LEGO Group</td>
                <td className="border border-gray-300 p-2">No</td>
              </tr>
            </tbody>
          </table>
        </div> */}
      </div>
      <Footer />
      {/* <ImpressumFooter /> */}
    </div>
  );
};

export default CookieDeclarationPage;
