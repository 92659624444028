export const Step7 = ({
  isRejected,
  scannedResult,
  packagePictures,
  contentPictures,
  nonConformingPictures,
  isWeightValid,
  handleFinalSubmit,
  handlePreviousStep,
  sortedItems,
  packageWeight,
  controlContainerWeight,
  isGreenLight,
  setIsGreenLight,
}) => {
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Step 7: Confirm and Submit</h2>
      <table className="min-w-full bg-white border border-gray-200">
        <tbody>
          <tr>
            <td className="border px-4 py-2 font-semibold">Package:</td>
            <td className="border px-4 py-2">{scannedResult.text}</td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-semibold">Package images:</td>
            <td className="border px-4 py-2">
              <div className="max-h-[250px] overflow-scroll">
                {packagePictures.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`takeback ${index}`}
                    className="w-full max-w-lg border rounded-lg mb-2"
                  />
                ))}
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-semibold">Content images:</td>
            <td className="border px-4 py-2">
              <div className="max-h-[250px] overflow-scroll">
                {contentPictures.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`takeback ${index}`}
                    className="w-full max-w-lg border rounded-lg mb-2"
                  />
                ))}
              </div>
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-semibold">
              Non-Conforming images:
            </td>
            <td className="border px-4 py-2">
              <div className="max-h-[250px] overflow-scroll">
                {nonConformingPictures.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt={`takeback ${index}`}
                    className="w-full max-w-lg border rounded-lg mb-2"
                  />
                ))}
              </div>
            </td>
          </tr>
          <tr className={!isWeightValid ? "bg-red-200" : ""}>
            <td className="border px-4 py-2 font-semibold">
              Control Container Weight:
            </td>
            <td className="border px-4 py-2">{controlContainerWeight} kg</td>
          </tr>
          <tr>
            <td
              className={`border px-4 py-2 font-semibold ${!isWeightValid && "bg-red-300"}`}
            >
              Contents Weight (minus control container):
            </td>
            <td
              className={`border px-4 py-2 ${!isWeightValid && "bg-red-300"}`}
            >
              {packageWeight - controlContainerWeight} kg
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-semibold">
              General Non-Conform Items Weight:
            </td>
            <td className="border px-4 py-2">
              {sortedItems.generalNonConform} kg
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-semibold">
              Assembled Non-Conform Lego Weight (minus control container):
            </td>
            <td className="border px-4 py-2">
              {Math.max(
                sortedItems.assembledNonConform,
                controlContainerWeight,
              ) - controlContainerWeight}{" "}
              kg
            </td>
          </tr>
          <tr>
            <td className="border px-4 py-2 font-semibold">
              Good Quality Lego Weight (minus control container):
            </td>
            <td className="border px-4 py-2">
              {Math.max(sortedItems.goodQualityLego, controlContainerWeight) -
                controlContainerWeight}{" "}
              kg
            </td>
          </tr>
          <tr className={`${isRejected && "bg-red-300"}`}>
            <td className="border px-4 py-2 font-semibold">Rejected?:</td>
            <td className="border px-4 py-2">{isRejected ? "Yes" : "No"}</td>
          </tr>
        </tbody>
      </table>
      <div className="flex items-center gap-2">
        <label htmlFor="needsReview">Needs review?</label>
        <input
          type="checkbox"
          name="needsReview"
          id="needsReview"
          checked={!isGreenLight}
        />
      </div>
      <div className="space-x-4">
        <button
          onClick={handlePreviousStep}
          className="bg-gray-500 hover:bg-gray-700 font-bold py-2 px-4 rounded"
        >
          Previous
        </button>
        <button
          onClick={handleFinalSubmit}
          className={`bg-green text-white hover:bg-blue-700 font-bold py-2 px-4 rounded ${!isWeightValid && "opacity-50 cursor-not-allowed"}`}
          disabled={!isWeightValid}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
