import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import "./fonts.css";

import AuthContext, { AuthProvider } from "contexts/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { useContext } from "react";

import * as pages from "./pages";

import PasswordLayout from "layouts/password";

import { isAdminEmail } from "consts/user";
import TradeInForm from "layouts/trade-in-form";
import StoreViewLayout from "layouts/store-view";
import StoreSignupLayout from "layouts/store-signup";
import ModalProvider from "layouts/modal-provider";

const queryClient = new QueryClient();

const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const isStoreRoute = window.location.pathname.includes("/store");

  if (!user) {
    return <Navigate to={t("urls.login")} />;
  }

  if (isStoreRoute && !isAdminEmail(user.email)) {
    return <Navigate to="/" />;
  }

  return children;
};

const createRouter = (lang, t) =>
  createBrowserRouter([
    {
      element: <ModalProvider />,
      children: [
        {
          path: "/",
          element: <Navigate to={`/${lang}`} replace />,
        },
        {
          path: `/${lang}`,
          children: [
            { path: "", element: <pages.Landing /> },
            { path: "uat", element: <pages.Uat /> },
            { path: "impressum", element: <pages.Impressum /> },
            { path: t("urls.unsubscribe"), element: <pages.Unsubscribe /> },
            {
              path: t("urls.manage-email-preferences"),
              element: <pages.Unsubscribe />,
            },
            {
              path: t("urls.login"),
              element: <pages.Login />,
            },
            {
              path: t("urls.scale"),
              element: <pages.Scales />,
            },
            {
              path: t("urls.kiosk"),
              element: <pages.Kiosk />,
            },
            {
              path: t("urls.holding"),
              element: <pages.ComingSoon />,
            },
            {
              path: t("urls.my_trade_ins"),
              element: <pages.MyTradeIns />,
            },
            {
              path: t("urls.georestrict"),
              element: <pages.GeoRestrict />,
            },
            {
              path: t("urls.preparation_checklist"),
              element: <pages.PreperationChecklist />,
            },
            {
              path: t("urls.terms_and_conditions"),
              element: <pages.TermsConditionsPage />,
            },
            {
              path: t("urls.privacy_policy"),
              element: <pages.PrivacyPolicyPage />,
            },
            {
              path: t("urls.cookie_policy"),
              element: <pages.CookiePolicyPage />,
            },
            {
              path: t("urls.cookie_declaration"),
              element: <pages.CookieDeclarationPage />,
            },
            {
              path: t("urls.trade_in"),
              element: <TradeInForm />,
              children: [
                {
                  path: "",
                  element: <Navigate to={t("urls.trade_in_weight")} />,
                },
                {
                  path: t("urls.trade_in_weight"),
                  element: <pages.WeightPage />,
                },
                {
                  path: t("urls.trade_in_method"),
                  element: <pages.MethodPage />,
                },
                {
                  path: t("urls.trade_in_checklist"),
                  element: <pages.ChecklistPage />,
                },
                {
                  path: t("urls.trade_in_payout_method"),
                  element: <pages.PayoutMethodPage />,
                },
                {
                  path: t("urls.trade_in_email"),
                  element: <pages.EmailPage />,
                },
                {
                  path: t("urls.trade_in_sign_in"),
                  element: <pages.SignInPage />,
                },
                {
                  path: t("urls.trade_in_sign_up"),
                  element: <pages.SignUpPage />,
                },
                {
                  path: t("urls.trade_in_verification"),
                  element: <pages.VerificationPage />,
                },
                {
                  path: t("urls.trade_in_terms"),
                  element: <pages.TermsPage />,
                },
                {
                  path: t("urls.trade_in_summary"),
                  element: <pages.SummaryPage />,
                },
                {
                  path: t("urls.trade_in_next_steps"),
                  element: <pages.NextStepsPage />,
                },
                {
                  path: t("urls.trade_in_qr_code"),
                  element: <pages.OnlineQrCode />,
                },
              ],
            },
            {
              path: t("urls.store"),
              element: (
                <ProtectedRoute>
                  <StoreViewLayout />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: t("urls.store_schedule"),
                  element: <pages.Schedule />,
                },
                {
                  path: t("urls.store_trade_ins"),
                  element: <pages.TradeIns />,
                },
                {
                  path: t("urls.store_create_shipment"),
                  element: <pages.CreateShipmentForm />,
                },
                {
                  path: t("urls.store_package_qr_code"),
                  element: <pages.GeneratePackageQRCode />,
                },
                {
                  path: t("urls.store_shipping_label"),
                  element: <pages.ShipmentLabel />,
                },
                {
                  path: t("urls.store_confirm_weight"),
                  element: <pages.ConfirmWeight />,
                },
                {
                  path: t("urls.store_trade_in"),
                  element: <pages.CreateTradeIn />,
                },
                {
                  path: t("urls.store_trade_in_weight"),
                  element: <pages.TradeInWeight />,
                },
                {
                  path: t("urls.store_payout"),
                  element: <pages.StorePayout />,
                },
                {
                  path: t("urls.store_payout_confirm"),
                  element: <pages.StorePayoutConfirm />,
                },
                {
                  path: t("urls.store_terms"),
                  element: <pages.StoreTerms />,
                },
                {
                  path: t("urls.store_confirmation"),
                  element: <pages.StoreTradeIFinal />,
                },
                {
                  path: t("urls.store_check_in"),
                  element: <pages.CheckIn1 />,
                },
                {
                  path: t("urls.store_check_in_email"),
                  element: <pages.CheckInEmail />,
                },
                {
                  path: t("urls.store_check_in_tradeins_email"),
                  element: <pages.CheckInTakebacks />,
                },
                {
                  path: t("urls.store_trade_in_confirmation"),
                  element: <pages.TradeInSummary />,
                },
              ],
            },
            {
              path: t("urls.store_no_header"),
              element: <StoreViewLayout showHeader={false} />,
              children: [
                {
                  path: t("urls.store_check_in_qr"),
                  element: <pages.CheckInQr />,
                },
                {
                  path: t("urls.store_trade_in_start"),
                  element: <pages.TradeInQr />,
                },
                {
                  path: t("urls.store_trade_in_password"),
                  element: <pages.TradeInPassword />,
                },
                {
                  path: t("urls.signup_store_qr_entrance"),
                  element: <pages.InStoreQR />,
                },
              ],
            },
            {
              path: t("urls.signup_store"),
              element: <StoreSignupLayout />,
              children: [
                {
                  path: t("urls.signup_store_start"),
                  element: <pages.StoreChecklist />,
                },
                {
                  path: t("urls.signup_store_email"),
                  element: <pages.StoreEmail />,
                },
                {
                  path: t("urls.signup_store_sign_in"),
                  element: <pages.InStoreSignin />,
                },
                {
                  path: t("urls.signup_store_sign_in_exists"),
                  element: <pages.InStoreSigninExists />,
                },
                {
                  path: t("urls.signup_store_sign_up"),
                  element: <pages.InStoreSignup />,
                },
                {
                  path: t("urls.signup_store_email_confirm"),
                  element: <pages.InStoreEmailConfirm />,
                },
                {
                  path: t("urls.signup_store_confirmation"),
                  element: <pages.InStoreConfirmation />,
                },
                {
                  path: t("urls.signup_store_confirmation_exists"),
                  element: <pages.InStoreConfirmationExists />,
                },
              ],
            },
            {
              path: t("urls.password"),
              element: <PasswordLayout />,
              children: [
                {
                  path: t("urls.password_forgot"),
                  element: <pages.PasswordForgot />,
                },
                {
                  path: t("urls.password_update"),
                  element: <pages.PasswordUpdate />,
                },
              ],
            },
            {
              path: t("urls.warehouse"),
              element: <pages.ReceiveTakeback />,
            },
          ],
        },
        {
          path: "*",
          element: <Navigate to={`/${lang}`} replace />,
        },
      ],
    },
  ]);

const UNPROTECTED_ROUTES = (t) => [
  "",
  "/",
  `${t("urls.landing")}/`,
  t("urls.login"),
  t("urls.sign_up"),
  t("urls.holding"),
  t("urls.landing"),
  t("urls.trade_in_weight"),
  t("urls.trade_in_method"),
  t("urls.trade_in_checklist"),
  t("urls.trade_in_payout_method"),
  t("urls.trade_in_email"),
  t("urls.trade_in_sign_in"),
  t("urls.trade_in_sign_up"),
  t("urls.trade_in_summary"),
  t("urls.scale"),
  t("urls.kiosk"),
  t("urls.signup_store_start"),
  t("urls.signup_store_qr_entrance"),
  t("urls.signup_store_email_confirm"),
  t("urls.signup_store_confirmation_exists"),
  t("urls.signup_store_confirmation"),
  t("urls.signup_store_email"),
  t("urls.signup_store_sign_up"),
  t("urls.signup_store_sign_in"),
  t("urls.signup_store_sign_in_exists"),
  t("urls.trade_in_verification"),
  t("urls.georestrict"),
  t("urls.preparation_checklist"),
  t("urls.password_forgot"),
  t("urls.password_update"),
  t("urls.terms_and_conditions"),
  t("urls.privacy_policy"),
  t("urls.cookie_policy"),
  t("urls.cookie_declaration"),
  t("urls.store_trade_in_password"),
  "/en-US/uat",
  "/de-DE/impressum",
];

const SUPPORTED_LANGUAGES = ["en-US"];

const WrappedRouter = () => {
  const { user } = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  const pathLang = window.location.pathname.split("/")[1];
  const userLang = navigator.language || navigator.userLanguage;
  const defaultLang = SUPPORTED_LANGUAGES.includes(pathLang)
    ? pathLang
    : SUPPORTED_LANGUAGES.includes(userLang)
    ? userLang
    : "en-US";

  if (i18n.language !== defaultLang) {
    i18n.changeLanguage(defaultLang);
  }

  if (!user && !UNPROTECTED_ROUTES(t).includes(window.location.pathname)) {
    return window.location.assign(
      `${t("urls.login")}?redirectTo=${window.location.pathname}`
    );
  }

  return <RouterProvider router={createRouter(defaultLang, t)} />;
};

const App = () => {
  const { i18n } = useTranslation();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Helmet>
          <html lang={i18n.language} />
          <style type="text/css">
            {`.cky-consent-container a { color: #1863dc !important; text-decoration: underline; }`}
          </style>
          {/* <meta name="description" content={t("meta.description")} />
          <meta property="og:description" content={t("meta.description")} />
          <meta
            property="twitter:description"
            content={t("meta.description")}
          /> */}
        </Helmet>
        <WrappedRouter />
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
