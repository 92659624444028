import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import enUS from "./locales/en/translations.json";
// import de from "./locales/de/translations.json";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en-US",
    debug: true,
    resources: {
      "en-US": {
        translation: enUS,
      },
      // "de-DE": {
      //   translation: de,
      // },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
