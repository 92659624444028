import React, { useState, useRef } from "react";
import KioskStartButton from "../components/start-button";
import TermsText from "components/terms-text";
import { useTranslation } from "react-i18next";
import Checklist from "components/checklist";

export default function TermsKiosk({ setScreenIndex, setInputWeight }) {
  const { t } = useTranslation();

  const checklistItems1 = [
    { name: "item1", label: t("trade-in.terms.check1") },
  ];

  const checklistItems2 = [
    { name: "item2", label: t("trade-in.terms.check2") },
    {
      name: "item3",
      label:
        "I understand that each time my LEGO bricks are cleared from the weighing tray into the kiosk, they are no longer retrievable, and as such, I am agreeing to the transfer of ownership.",
    },
  ];

  const containerRef = useRef(null);
  const [termsChecked, setTermsChecked] = useState(false);
  const [othersChecked, setOthersChecked] = useState(false);

  const handleSubmit = () => {
    setInputWeight(0);
    setScreenIndex(2);
  };

  return (
    <div className="flex flex-col items-center overflow-scroll justify-between w-full h-full px-[4vh]">
      <h1 className="text-[122px] text-center max-w-[50vh] text-white mt-[3vh] px-[4vh]">
        The legal stuff
      </h1>
      <div className="flex flex-col">
        <label className="mb-regular text-[1.5vh] text-white font-medium">
          Terms and Conditions
        </label>
        <div
          ref={containerRef}
          className="overflow-y-scroll h-[30vh] bg-[#006a2a] w-full mb-[1vh] p-[2vh] rounded-[1.5vh] kiosk"
        >
          <TermsText scroll={false} whiteText={true} />
        </div>
        <div>
          <Checklist
            checklistItems={checklistItems1}
            setAllChecked={setTermsChecked}
            className="mt-[1.5vh] text-[1.5vh]"
            kioskView={true}
          />
        </div>
        <Checklist
          checklistItems={checklistItems2}
          setAllChecked={setOthersChecked}
          className="mt-[1.5vh] text-[1.5vh]"
          kioskView={true}
        />
        {/* <div className="mt-8">
          <Trans
            i18nKey="trade-in.terms.add1"
            components={{
              1: (
                <Link
                  to={t("urls.terms_conditions")}
                  className="text-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </div> */}
      </div>
      <div className="grid grid-cols-2 w-full pb-2 pt-large gap-[2vh] relative z-10">
        <KioskStartButton className="w-full" onClick={() => setScreenIndex(0)}>
          Cancel
        </KioskStartButton>
        <KioskStartButton
          className="w-full font-bold"
          onClick={() => handleSubmit()}
          color="yellow"
          disabled={!termsChecked || !othersChecked}
          icon="arrow_forward"
          iconColor="black"
        >
          Continue
        </KioskStartButton>
      </div>
    </div>
  );
}
