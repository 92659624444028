import { useContext, useState } from "react";
import { Button } from "components/button";
import { Header } from "components/header";
import MyTradeInsCard from "components/my-trade-ins-card";
// import { useMyTakebacks } from "api/use-takebacks";
import PayallModal from "components/payall-modal";
import { useTranslation } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import ImpressumFooter from "components/footer-impressum";
import useSessionTakebacks from "hooks/useSessionTakebacks";
import LoadingSpinner from "components/loading-spinner";
import { makeSnowDate } from "lib/utils";
import { useSendEmailUpdate } from "api/use-email";
import AuthContext from "contexts/AuthContext";
import { calculateEstimate } from "lib/calculateEstimate";

export const MyTradeIns = () => {
  // const { data: takebacks, refetch } = useMyTakebacks();
  const [isLoading, setIsLoading] = useState(true);

  const { takebacks, updateAllTakebacks, clearTakebacks } =
    useSessionTakebacks();

  const { user } = useContext(AuthContext);

  //simulate loading
  useState(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);

  // State to update the payment status after Payall modal is completed without needing a page reload / re-fetch
  const [payallCompleted, setPayallCompleted] = useState(false);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     refetch();
  //   }, 2000);

  //   return () => clearInterval(intervalId);
  // }, [refetch]);

  const latestTb = takebacks?.sort(
    (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
  )[0];

  console.log(takebacks);

  const { country, state } = latestTb?.consumer ?? {};

  const { mutate: sendVerifiedEmail } = useSendEmailUpdate();

  return (
    <div className="min-h-screen relative flex flex-col justify-between">
      <div>
        <Header />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="container min-h-[calc(100vh-160px)] mx-auto pt-16 px-4 md:px-0 flex flex-col justify-between gap-8">
            <div>
              <h1 className="text-6xl font-semibold">
                {t("my_trade_ins.title")}
              </h1>
              {takebacks?.map((takeback) => (
                <>
                  <AnimatePresence>
                    {modalOpen === takeback.number && (
                      <PayallModal
                        caseId={takeback.number}
                        setModalOpen={setModalOpen}
                        setPayallCompleted={(payallComplete) => {
                          // refetch();
                          setPayallCompleted(payallComplete);
                        }}
                      />
                    )}
                  </AnimatePresence>
                  <MyTradeInsCard
                    key={takeback.id}
                    weight={parseFloat(takeback.presortWeight).toFixed(1)}
                    // u_tango_redeem_link={takeback.u_tango_redeem_link}
                    returnMethod={takeback.takebackMethod}
                    createdAt={takeback.createdAt}
                    receivedAt={null}
                    verifiedAt={takeback.verifiedAt}
                    // takebackIban={takeback.u_lego_seller_iban}
                    issuedAt={null}
                    payoutMethod={takeback.payoutMethod}
                    unit="lbs"
                    caseId={takeback?.id}
                    takeBackCountry="en-US"
                    currencySymbol="$"
                    shipping={null}
                    sys_id={takeback.id}
                    setPayallOpen={() => setModalOpen(takeback.number)}
                    payallCompleted={payallCompleted}
                  />
                </>
              ))}
              <div className="mt-8 flex gap-4">
                <Button
                  href={t("urls.trade_in_weight")}
                  variant="secondary"
                  queryParams={{
                    country: country,
                    state: state,
                  }}
                >
                  {t("ui.button_start")}
                </Button>
              </div>
            </div>
            {takebacks.length !== 0 && (
              <div className="flex flex-col md:flex-row gap-2">
                <Button
                  variant="secondary"
                  className="w-fit"
                  onClick={() => {
                    updateAllTakebacks({
                      verifiedAt: makeSnowDate(new Date()),
                    });
                    const lastTakeback = takebacks[takebacks.length - 1];
                    const weight = parseFloat(lastTakeback.presortWeight);
                    const reward = lastTakeback.payoutMethod;
                    console.log(lastTakeback);
                    const value =
                      reward === "giftcard"
                        ? calculateEstimate(weight, "US").gift
                        : calculateEstimate(weight, "US").payment;
                    const formattedReward =
                      reward === "giftcard" ? "e-Gift Card" : "Digital Cash";
                    sendVerifiedEmail(
                      {
                        emailType: `verified`,
                        userEmail: user.email.trim(),
                        weight: weight,
                        value: value,
                        reward: formattedReward,
                      },
                      {
                        onSuccess: () => {},
                      }
                    );
                  }}
                >
                  Set Verified (Demo)
                </Button>
                <Button
                  variant="secondary"
                  className="w-fit"
                  onClick={() => {
                    clearTakebacks();
                  }}
                >
                  Clear All (Demo)
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <ImpressumFooter className="" />
    </div>
  );
};
