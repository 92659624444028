import React, {
  // useRef,

  useContext,
} from "react";
import AuthContext from "contexts/AuthContext";
import { useLocation } from "react-router-dom";
import { useCurrentLocation, getQueryParams } from "lib/utils";
import { useTranslation } from "react-i18next";

import "material-symbols/outlined.css";
import "./style.css";

import { Header } from "components/header";
import { Footer } from "components/footer/footer";
import { Button } from "components/button";

//calc
// import { calculateEstimate } from "lib/calculateEstimate";
// import InputValidation from "components/input-validation";
// //import { formatEstimationNumber } from "lib/utils";
// import weightRestrictions from "data/weight-restrictions.json";

//query params
import { LOCALE_NAME, STATE_NAME, COUNTRY_NAME } from "consts/query-params";

//assets
import DemoHero from "assets/DemoBrandHero.jpg";
import DemoHeroMobile from "assets/DemoBrandHeroMobile.jpg";

// import LegoMan from "assets/legoman.png";
// import BgMailIn from "assets/landingMailIn.png";
// import BgMailInEuro from "assets/landingMailInEuro.png";
// import BgInStore from "assets/landingInStore.png";
// import BgCredit from "assets/landingCredit.png";
// import BgEstimate from "assets/landingEstimate.png";
// import BgEstimateEuro from "assets/landingEstimateEuro.png";
// // import BgEstimateEuro from "assets/bgEuro.jpg";
// import BgBricks from "assets/landingBricks.png";
// import BgAccepted from "assets/landingAccepted.png";
// import BgNotAccepted from "assets/landingNotAccepted.png";
// import BgLandingHero from "assets/landingHero.png";
// import BgLandingHeroEuro from "assets/landingHeroEuro.png";
// import useWhen from "hooks/useWhen";
// import { PAYMENT_ARGS } from "consts/feature-config";
// import useLocalizedCurrencyValue from "hooks/useLocalizedCurrencyValue";
// import RewardOverviewLanding from "components/reward-overview-landing";

const Landing = () => {
  // const [takeBackTypeToggle, setTakeBackTypeToggle] = useState("mail");
  // const [showInStore, setShowInStore] = useState(false);
  // const [weightInput, setWeightInput] = useState("");
  // const [weightAlarm, setWeightAlarm] = useState(null);

  // translation
  const { t } = useTranslation();
  // const { i18n } = useTranslation();

  // auth
  const { user } = useContext(AuthContext);

  // Get query params
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  // Get location settings
  const { location: currentLocation } = useCurrentLocation();

  // const unit = `${t("scale.weight")}`;
  // const storeLocation =
  //   currentLocation && currentLocation[STATE_NAME] === "GA"
  //     ? "Sugarloaf Mills"
  //     : "Colorado Mills";

  const CtaButton = ({ className }) => {
    return (
      <Button
        href={`${t("urls.trade_in_weight")}`}
        queryParams={{
          [LOCALE_NAME]: currentLocation.locale,
          [STATE_NAME]: currentLocation.state,
          [COUNTRY_NAME]: currentLocation.country,
          // ...(parsedWeightInput && !weightAlarm && { w: parsedWeightInput }),
        }}
        className={className}
      >
        {t("landing.button_start_new")}
      </Button>
    );
  };

  // const moveTo = (elementId, offset = 0) => {
  //   const element = document.getElementById(elementId);
  //   if (element) {
  //     const elementPosition =
  //       element.getBoundingClientRect().top + window.scrollY;
  //     const offsetPosition = elementPosition - offset;
  //     window.scrollTo({ top: offsetPosition, behavior: "smooth" });
  //   }
  // };

  // const handleScrollTo = (e, elementId, offset) => {
  //   e.preventDefault();
  //   moveTo(elementId, offset);
  // };

  // const updateShowInStore = useCallback(() => {
  //   setShowInStore(
  //     currentLocation.takebackMethod.some(
  //       (method) => method === "in_store_co" || method === "in_store_ga"
  //     )
  //   );
  // }, [currentLocation]);

  // useEffect(() => {
  //   updateShowInStore();
  // }, [updateShowInStore]);

  // const parsedWeightInput = parseFloat(weightInput.replace(",", "."));
  // const estimate = calculateEstimate(
  //   parsedWeightInput,
  //   currentLocation?.country
  // );
  // const pricePerUnit = calculateEstimate(1, currentLocation?.country);
  // const singleValue =
  //   currentLocation?.takebackDisbursement?.includes("giftcard") &&
  //   currentLocation?.takebackDisbursement?.length === 1
  //     ? estimate.gift
  //     : estimate.payment;
  // const displaySingleValue =
  //   currentLocation?.locale !== "en-US"
  //     ? singleValue.toString().replace(".", ",")
  // //     : singleValue;
  // const estimateGift = useLocalizedCurrencyValue(estimate.gift);
  // const estimateCash = useLocalizedCurrencyValue(estimate.payment);
  // const pricePerUnitGift = useLocalizedCurrencyValue(pricePerUnit.gift);
  // const pricePerUnitCash = useLocalizedCurrencyValue(pricePerUnit.payment);

  // const maxWeight =
  //   currentLocation?.takebackMethod.includes("mail") &&
  //   currentLocation?.takebackMethod.length === 1
  //     ? weightRestrictions.find((weight) => weight.unit === unit).maxMail
  //     : weightRestrictions.find((weight) => weight.unit === unit).maxInstore;

  // const minWeight = weightRestrictions.find(
  //   (weight) => weight.unit === unit
  // ).minMail;

  // const handleCalcInput = (e) => {
  //   let inputValue = e.target.value;

  //   if (currentLocation?.locale !== "en-US") {
  //     inputValue = inputValue.replace(/[^\d,]/g, "");
  //     const parts = inputValue.split(",");

  //     if (parts[1]) {
  //       inputValue = `${parts[0]},${parts[1].slice(0, 1)}`;
  //     }

  //     const numericValue = parseFloat(inputValue.replace(",", "."));
  //     e.target.value =
  //       !isNaN(numericValue) && numericValue >= 0
  //         ? inputValue
  //         : inputValue === ","
  //         ? ","
  //         : "";
  //   } else {
  //     inputValue = inputValue.replace(/[^\d.]/g, "");
  //     const parts = inputValue.split(".");

  //     if (parts[1]) {
  //       inputValue = `${parts[0]}.${parts[1].slice(0, 1)}`;
  //     }

  //     const numericValue = parseFloat(inputValue);
  //     e.target.value =
  //       !isNaN(numericValue) && numericValue >= 0
  //         ? inputValue
  //         : inputValue === "."
  //         ? "."
  //         : "";
  //   }

  //   handleCalcInputChange(e);
  // };

  // const handleCalcInputChange = (event) => {
  //   const newValue = event.target.value;
  //   setWeightInput(newValue);
  //   setWeightAlarm(null);
  //   const numericValue = parseFloat(newValue.replace(",", "."));

  //   if (!isNaN(numericValue)) {
  //     if (maxWeight && numericValue > maxWeight) {
  //       setWeightAlarm(
  //         t("ui.weight_alarm_max", {
  //           maxWeight: maxWeight,
  //           unit: unit,
  //         })
  //       );
  //     } else if (minWeight && numericValue < minWeight) {
  //       setWeightAlarm(
  //         t("ui.weight_alarm_min", {
  //           minWeight: minWeight,
  //           unit: unit,
  //         })
  //       );
  //     } else {
  //       setWeightAlarm(null);
  //     }
  //   } else {
  //     setWeightAlarm(null);
  //   }
  // };

  // const AccordionTrigger = React.forwardRef(
  //   ({ children, className, ...props }, forwardedRef) => (
  //     <Accordion.Header className="AccordionHeader text-primary text-2xl">
  //       <Accordion.Trigger
  //         className={classNames("AccordionTrigger text-left", className)}
  //         {...props}
  //         ref={forwardedRef}
  //       >
  //         <div>{children}</div>
  //         <span
  //           className="AccordionChevron material-symbols-outlined align-bottom ml-large text-secondary"
  //           aria-hidden
  //         >
  //           keyboard_arrow_down
  //         </span>
  //       </Accordion.Trigger>
  //     </Accordion.Header>
  //   )
  // );

  // const AccordionContent = React.forwardRef(
  //   ({ children, className, ...props }, forwardedRef) => (
  //     <Accordion.Content
  //       className={classNames("AccordionContent", className)}
  //       {...props}
  //       ref={forwardedRef}
  //     >
  //       <div className="AccordionContentText text-secondary mt-large mb-regular">
  //         {children}
  //       </div>
  //     </Accordion.Content>
  //   )
  // );

  //only show cash payment in appropriate environments
  // const showPayment = useWhen(PAYMENT_ARGS);

  return (
    <div>
      <Header className="">
        <div className="flex gap-large">
          <CtaButton className="hidden md:block" />
          <Button
            href={user ? `${t("urls.my_trade_ins")}` : `${t("urls.login")}`}
            variant="secondary"
            queryParams={queryParams}
          >
            {user
              ? t("landing.button_my_trade_ins")
              : t("landing.button_sign_in")}
          </Button>
        </div>
      </Header>
      <div id="hero" className="relative">
        <img
          src={DemoHero}
          alt=""
          className="w-full h-full object-cover hidden lg:block aspect-[17/9]"
        />
        <img
          src={DemoHeroMobile}
          alt=""
          className="w-full h-full object-cover lg:hidden -translate-y-[calc(95vw-280px)] -mb-[calc(95vw-280px)]"
        />
        <div className="absolute top-[32px] left-[32px] lg:top-[64px] lg:left-[64px] xl:top-[128px] xl:left-[128px] ">
          <div className="text-2xl text-[#323232] uppercase">
            DEMOBRAND TRADE-IN
          </div>
          <div className="leading-tight text-[32px] lg:text-[48px] xl:text-[72px] max-w-[10.5em] mt-[8px]">
            Give Your Old Suitcase a Second Journey
          </div>
          <div className="text-base lg:text-2xl leading-relaxed max-w-[90%] lg:max-w-[620px] xl:max-w-[700px] mt-[16px] lg:mt-[32px] text-[#323232]">
            Every suitcase has a story. Let yours continue with our sustainable
            takeback program. Trade in your old luggage, and we'll recycle it
            responsibly while you earn rewards for your next travel companion.
          </div>
          <CtaButton className="mt-[32px]" />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Landing;
