import React, { useCallback, useEffect, useState, useRef } from "react";
import logo from "assets/logo.jpg";
import QRCode from "react-qr-code";
import CryptoJS from "crypto-js";
import bricks from "./bricks.svg";
import happyLego from "./happy-lego.svg";
import screensaver from "./screensaver.svg";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { calculateEstimate } from "lib/calculateEstimate";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";

const { REACT_APP_API } = process.env;

const HappyLego = () => (
  <img className="w-full" alt="happy lego" src={happyLego} />
);

const Screensaver = () => (
  <img className="h-full" alt="screensaver" src={screensaver} />
);

export const SIGNING_KEY = `cdjedlehccbhnbukhtdlrnlifgtdhlnltglifgtdhlctlhuejt`;

export const Scales = () => {
  const { t } = useTranslation();
  const [number, setNumber] = useState("");
  const [weight, setWeight] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [valueMode, setValueMode] = useState(false);
  const [screensaverActive, setScreensaverActive] = useState(true);

  const screensaverTimerRef = useRef(null);
  const valueModeTimerRef = useRef(null);
  const inactivityTimerRef = useRef(null);
  const animationIntervalRef = useRef(null);

  useEffect(() => {
    const checkAndReload = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      if (
        (currentHour === 6 && currentMinute === 0) ||
        (currentHour === 22 && currentMinute === 0)
      ) {
        window.location.reload();
      }
    };

    const intervalId = setInterval(checkAndReload, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const resetTimers = useCallback(() => {
    if (screensaverTimerRef.current) {
      clearTimeout(screensaverTimerRef.current);
      screensaverTimerRef.current = null;
    }
    if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
      inactivityTimerRef.current = null;
    }
    if (animationIntervalRef.current) {
      clearInterval(animationIntervalRef.current);
      animationIntervalRef.current = null;
    }
  }, []);

  const startScreensaverTimer = useCallback(() => {
    resetTimers();
    screensaverTimerRef.current = setTimeout(() => {
      setScreensaverActive(true);
      setWeight(0);
      setValueMode(false);
    }, 30000);
  }, [resetTimers]);

  const handleKeyPress = useCallback(
    (event) => {
      const keyPressed = event.key;
      if (!isNaN(keyPressed) || keyPressed === ".") {
        setNumber((prev) => (prev + keyPressed).slice(0, 6));
      } else if (keyPressed === "Enter") {
        const newWeight = parseFloat(number).toFixed(2);
        setNumber("");

        const weightDifference = Math.abs(newWeight - weight);

        if (!isNaN(newWeight) && weightDifference >= 0.045) {
          setWeight(parseFloat(newWeight));
          setScreensaverActive(false);
          setAnimate(newWeight !== 0);
          resetTimers();
          startScreensaverTimer();

          // Send to KV
          const timestamp = new Date().toISOString();
          getLocation().then((location) => {
            sendToKV({ weight: newWeight, location, timestamp });
          });
        }
      }
    },
    [number, weight, resetTimers, startScreensaverTimer],
  );

  const getLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          },
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const sendToKV = async (data) => {
    try {
      await fetch(`${REACT_APP_API}/audit/scale`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("Error sending data to KV:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleKeyPress(event);
    };

    window.addEventListener("keydown", handleKeyDown);
    startScreensaverTimer();

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      resetTimers();
    };
  }, [handleKeyPress, startScreensaverTimer, resetTimers]);

  useEffect(() => {
    if (valueModeTimerRef.current) {
      clearTimeout(valueModeTimerRef.current);
    }

    if (weight > 0) {
      valueModeTimerRef.current = setTimeout(() => {
        setValueMode(true);
      }, 5000);
    }
  }, [valueMode, weight]);

  useEffect(() => {
    if (valueMode) {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
      inactivityTimerRef.current = setTimeout(() => {
        setWeight(0);
        setValueMode(false);
        setScreensaverActive(true);
      }, 30000);
    }
  }, [valueMode]);

  // const calculateValue = (weight) => weight * 4;
  const convertWeight = (weight) => {
    const weightInlbs = (weight * 2.20462).toFixed(2);
    return Math.ceil(weightInlbs * 10) / 10;
  };

  const displayWeightConverted = convertWeight(weight);

  const signedWeight = CryptoJS.SHA256(
    `${displayWeightConverted}_${SIGNING_KEY}`,
  ).toString(CryptoJS.enc.Hex);

  //only show cash payment in appropriate environments
  const showPayment = useWhen(PAYMENT_ARGS);

  return (
    <div
      className={`h-screen overflow-hidden ${valueMode && "relative bg-[#fff]"
        }`}
    >
      {!screensaverActive && (
        <>
          <div className="flex flex-col h-screen overflow-hidden w-full absolute z-50">
            <div className="flex flex-col justify-center items-center">
              <img
                src={logo}
                alt="LEGO Logo"
                className="h-[170px] my-[150px]"
              />
              <p className="text-[59px] font-semibold">
                {valueMode
                  ? "Cha-ching! Your LEGO stash is worth"
                  : "Your LEGO® bricks weigh"}
              </p>
              <span className={`text-[223px] font-bold relative`}>
                {valueMode ? (
                  <>
                    {showPayment ? (
                      <div className="flex justify-center">
                        <div className="flex flex-col items-center">
                          <div className="flex">
                            <span className="text-[65px] inline-block relative -top-[0px] right-[10px]">
                              {t(`currency.symbol`)}
                            </span>
                            <span
                              className={`${showPayment && "text-[140px]"}`}
                            >
                              {/* {calculateValue(displayWeightConverted).toFixed(2)} */}
                              {
                                calculateEstimate(displayWeightConverted, "US")
                                  .payment
                              }
                            </span>
                          </div>
                          <div className="text-[50px] w-full text-center font-normal">
                            As Digital Cash
                          </div>
                        </div>
                        <span
                          className={`${showPayment && "text-[140px]"
                            } mx-[32px]`}
                        >
                          -
                        </span>
                        <div className="flex flex-col items-center">
                          <div className="flex">
                            <span className="text-[65px] inline-block relative -top-[0px] right-[10px]">
                              {t(`currency.symbol`)}
                            </span>
                            <span
                              className={`${showPayment && "text-[140px]"}`}
                            >
                              {/* {calculateValue(displayWeightConverted).toFixed(2)} */}
                              {
                                calculateEstimate(displayWeightConverted, "US")
                                  .gift
                              }
                            </span>
                          </div>
                          <div className="text-[50px] w-full text-center font-normal">
                            As a LEGO e-Gift Card
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <span className="text-[65px] inline-block relative -top-[114px] right-[10px]">
                          {t(`currency.symbol`)}
                        </span>
                        <span className={`${showPayment && "text-[160px]"}`}>
                          {/* {calculateValue(displayWeightConverted).toFixed(2)} */}
                          {calculateEstimate(displayWeightConverted, "US").gift}
                        </span>
                      </>
                    )}

                    <p
                      className={`${showPayment
                          ? "mt-[40px] font-bold"
                          : "-mt-[40px] font-normal"
                        } text-[50px] w-full text-center -mt-[40px] relative`}
                    >
                      weight {displayWeightConverted}
                      {t("scale.weight")}
                    </p>
                    <div className="fixed bottom-[80px] left-[80px] z-50 border-[20px] border-white">
                      <QRCode
                        value={`${displayWeightConverted}_${signedWeight}`}
                        size={256}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <span>{displayWeightConverted || 0}</span>
                    <span className="text-[93px]">{t("scale.weight")}</span>
                  </>
                )}
              </span>
            </div>
          </div>
          <div
            className={`absolute w-full overflow-hidden top-[100vh] ${animate && !valueMode ? "animate-up" : ""
              }`}
          >
            <img alt="lego bricks" src={bricks} className="w-full h-auto" />
          </div>
          {valueMode && (
            <div className="w-screen inset-0 bg-[#fff]">
              <HappyLego />
            </div>
          )}
        </>
      )}
      {screensaverActive && (
        <div className="h-screen absolute inset-0 z-50 bg-[#43A1E1]">
          <Screensaver />
        </div>
      )}
    </div>
  );
};
