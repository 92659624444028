import React, { useState, useContext, useEffect } from "react";
import { Button } from "components/button";
import AuthContext from "contexts/AuthContext";
// import { isAdminEmail } from "consts/user";
import { Header } from "components/header";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Input } from "components/input";
// import { Link } from "react-router-dom";
import InputValidation from "components/input-validation";
import LoadingSpinner from "components/loading-spinner";
import ForgotPasswordLink from "components/forgot-password-link";
import PasswordInput from "components/password-input";
import ImpressumFooter from "components/footer-impressum";
import { isValidEmail } from "lib/utils";
import { EmailValidation } from "components/email-validation";

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [emailAlarm, setEmailAlarm] = useState(false);

  const { user, loginHandler, loginError } = useContext(AuthContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const location = useLocation();

  const redirectTo = new URLSearchParams(location.search).get("redirectTo");

  const { t } = useTranslation();

  useEffect(() => {
    if (isRedirecting && user) {
      // const isAdmin = isAdminEmail(user.email);
      // if (isAdmin) {
      //   navigate(redirectTo || t("urls.store_check_in"));
      // } else {
      navigate(redirectTo || "/");
      // }
      setIsLoading(false);
      setIsRedirecting(false);
    }
  }, [isRedirecting, user, navigate, t, redirectTo]);

  const onSubmit = () => {
    if (!isValidEmail(email)) {
      setEmailAlarm(true);
    } else {
      setIsLoading("Signing In...");
      loginHandler(
        { username: email, password },
        {
          onSuccess: (data) => {
            if (data.locationId) {
              localStorage.setItem("locationId", data.locationId);
            }
            setIsRedirecting(true);
          },
        },
        {
          onError: () => {
            setIsLoading(false);
          },
        }
      );
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <div>
        <Header />
        {isLoading ? (
          <LoadingSpinner isLoading={isLoading} />
        ) : (
          <div className="flex items-start pt-24 justify-center min-h-[calc(100vh-81px)]">
            <div className="w-[min(36rem,100%)] px-8">
              <h2 className="font-semibold text-6xl mb-8 leading-normal">
                {t("login.title")}
              </h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="w-full flex flex-col gap-x-large"
              >
                <div>
                  <Input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label={t("ui.input_email")}
                  />
                  <EmailValidation emailAlarm={emailAlarm} />
                </div>
                <div>
                  <PasswordInput
                    label={t("ui.input_password")}
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="text-blue text-sm cursor-pointer mt-regular">
                    <ForgotPasswordLink returnToPath={t(`urls.login`)} />
                  </div>
                </div>
                {loginError && (
                  <InputValidation errorMessage={loginError.message} />
                )}
                <div className="flex w-full">
                  <Button type="submit" onClick={onSubmit}>
                    Sign In
                  </Button>
                </div>
              </form>
              {/* <div className="mt-8 text-left flex gap-1 items-start">
                <span
                  className={`material-symbols-outlined scale-75 text-blue align-bottom block`}
                >
                  info
                </span>{" "}
                <div className="text-sm text-gray-500">
                  <Trans
                    i18nKey="login.sign_up_instructions"
                    components={{
                      1: <Link to={t("urls.landing")} className="text-blue" />,
                    }}
                  />
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
      <ImpressumFooter />
    </div>
  );
};
