import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { motion } from "framer-motion";
// import LegoManWEBP from "assets/legoman.webp";
import LegoMan from "assets/legoman.png";
import PropTypes from "prop-types";
import Giftcard from "assets/DemoBrandGiftcard.jpg";
import Cash from "assets/DemoBrandCash.jpg";
import DemoBrand from "assets/suitcaseMini.png";

export default function WeightEstimation({
  className,
  singleValue,
  range = null, //can be array of two values
  animate,
  // showImage = false,
  title = "Your LEGO® bricks are worth",
  description = "Based on your input",
  image = LegoMan,
  visual = "legoMan",
  locale = "en-US",
  // imgSource = LegoManWEBP,
  minMaxReached = false,
}) {
  const showImage = true;
  const imgSource = DemoBrand;
  const { t } = useTranslation();
  const motionProps = animate
    ? {
        initial: { opacity: 0, y: 10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 10 },
        transition: { duration: 0.2, ease: "easeOut" },
      }
    : {};
  const pictureMotionProps = animate
    ? {
        initial: { opacity: 0, y: 10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 10 },
        transition: { duration: 0.2, ease: "easeOut", delay: 0.1 },
      }
    : {};

  return (
    <motion.div
      {...motionProps}
      className={classNames(
        "bg-[#ffc29989] rounded-2xl relative sm:mt-x-large flex justify-between",
        className
      )}
    >
      <div className="flex flex-col py-4 px-5 gap-regular">
        <div className="uppercase text-sm font-bold">{title}</div>
        <div
          className={`${
            minMaxReached ? "text-black/25 " : ""
          } text-6xl leading-none`}
        >
          {!minMaxReached ? (
            singleValue ? (
              <>
                {locale !== "en-US"
                  ? `${singleValue} ${t("currency.symbol")}`
                  : `${t("currency.symbol")}${singleValue}`}
              </>
            ) : range ? (
              <>
                {locale !== "en-US"
                  ? `${range[0]} ${t("currency.symbol")} - ${range[1]} ${t(
                      "currency.symbol"
                    )}`
                  : `${t("currency.symbol")}${range[0]} - ${t(
                      "currency.symbol"
                    )}${range[1]}`}
              </>
            ) : (
              "0.00"
            )
          ) : (
            "0.00"
          )}
        </div>
        <div className="text-secondary">
          {description.replace("{value}", singleValue)}
        </div>
      </div>
      {showImage && (
        <div className="w-fit flex items-center justify-center px-large relative">
          {visual === "cash" ? (
            <motion.img
              {...pictureMotionProps}
              className="w-[120px] md:w-[147px] rounded-md md:rounded-xl drop-shadow-xl"
              src={Cash}
              alt="A smiling LEGO figure"
            />
          ) : visual === "giftcard" ? (
            <motion.img
              {...pictureMotionProps}
              className="w-[120px] md:w-[147px] rounded-md md:rounded-xl drop-shadow-xl"
              src={Giftcard}
              alt="A smiling LEGO figure"
            />
          ) : (
            <div className="w-[100px]">
              <motion.picture
                {...pictureMotionProps}
                className="absolute -right-4 bottom-0 w-[120px] md:w-[172px]"
              >
                {imgSource === DemoBrand && (
                  <source type="image/webp" srcSet={imgSource} />
                )}
                <img
                  src={DemoBrand}
                  alt="A smiling LEGO figure"
                  className="h-auto object-cover"
                />
              </motion.picture>
            </div>
          )}
        </div>
      )}
    </motion.div>
  );
}

WeightEstimation.propTypes = {
  className: PropTypes.string,
  singleValue: PropTypes.number.isRequired,
  animate: PropTypes.bool,
  showImage: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imgSource: PropTypes.string,
};
