import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "lib/utils";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { useTranslation, Trans } from "react-i18next";
import AuthContext from "contexts/AuthContext";
import { createSearchParams } from "react-router-dom";
import { Button } from "components/button";

export default function InStoreEmailConfirm() {
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  const { t } = useTranslation();
  const emailInput = localStorage.getItem("emailInput");
  const { resendEmailVerificationHandler } = useContext(AuthContext);

  const handleResendEmail = () => {
    const params = queryParams
      ? createSearchParams(queryParams).toString()
      : "";
    resendEmailVerificationHandler(
      {
        email: emailInput,
        redirectTo: `${window.location.origin}${t(
          `urls.signup_store_sign_in`
        )}?${params}`,
      },
      {
        onSuccess: () => {},
      },
      {
        onError: (error) => {
          console.error("Failed to resend verification email", error.message);
        },
      }
    );
  };

  return (
    <FormContainer
      submitDisabled={!queryParams.e}
      submitParams={queryParams}
      secondaryLabel="Back"
      secondaryHref={`${t("urls.signup_store_email")}`}
    >
      <ProgressBar step={2} stepsNum={3} />
      <div className="flex flex-col gap-2">
        <h2 className="font-semibold text-6xl max-w-lg">
          {t("trade-in.verification.title")}
        </h2>
        <div className="w-full flex flex-col">
          <div>
            <span className="text-tertiary max-w-lg text-base leading-normal">
              <Trans
                i18nKey="trade-in.verification.description"
                components={{ email: emailInput }}
              />
            </span>
          </div>
          <Button
            variant="default"
            type="button"
            className="w-full md:w-1/2 mx-auto md:ml-0 mt-8"
            onClick={handleResendEmail}
          >
            {t("trade-in.verification.resend")}
          </Button>
        </div>
      </div>
    </FormContainer>
  );
}
