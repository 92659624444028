import * as React from "react";

export const TodaysDate = () => {
  const today = new Date();
  const day = today.getDate();
  const year = today.getFullYear();
  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  let monthIndex = today.getMonth();
  let monthName = monthNames[monthIndex];

  let suffix = "th";
  const exceptions = [1, 21, 31];
  if (exceptions.includes(day)) {
    suffix = "st";
  } else if ([2, 22].includes(day)) {
    suffix = "nd";
  } else if ([3, 23].includes(day)) {
    suffix = "rd";
  }

  return (
    <div className="flex items-center">
      <div className="text-tertiary text-3xl">
        {monthName} {day}{suffix} {year}
      </div>
    </div>
  );
};
