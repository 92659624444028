import React from 'react';
import ScheduleCard from 'components/schedule-card';
import { TodaysDate } from 'components/todays-date';
import BottomBar from 'components/bottom-bar';

export default function Schedule() {
  const appointments = [
    { time: '9:00AM', name: 'John Doe', weight: '12' },
    { time: '11:00AM', name: 'Jane Smith', weight: '50' },
    { time: '2:00PM', name: 'Bob Johnson', weight: '75' },
    { time: '2:00PM', name: 'Bob Johnson', weight: '75' },
  ];

  return (
    <div className="flex flex-col px-6 py-4 items-center min-h-full">
      <div className="flex flex-col mr-auto">
        <div>
          <h1 className="text-6xl pb-2 font-bold">Today's Schedule</h1>
          <TodaysDate />
        </div>
      </div>
      <div className="grid w-full gap-large">
        {appointments.map((appointment, index) => (
          <ScheduleCard
            key={index}
            time={appointment.time}
            name={appointment.name}
            weight={appointment.weight}
          />
        ))}
      </div>
      <BottomBar />
    </div>
  );
}
