export const signUpHtml = ({ linkUrl, hostname }) => `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><!--$-->
<html style="background-color:#FFF" dir="ltr" lang="en">
  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="max-width:37.5em;background-color:#FFFFFF;border:1px solid #DAE3F9;border-radius:4px">
    <tbody>
      <tr style="width:100%">
        <td>
          <head>
            <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
            <meta name="x-apple-disable-message-reformatting" />
            <style>
              @font-face {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                mso-font-alt: 'system-ui';
                src: url(https://fonts.googleapis.com/css2?family=Manrope:wght@400) format('undefined');
              }
              * {
                font-family: 'Manrope', system-ui;
              }
            </style>
          </head>
          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="padding:32px 24px 0;text-align:center">
            <tbody>
              <tr>
                <td><img alt="Demo Brand Logo" height="55" src="${hostname}/emailLogo.jpg" style="display:block;outline:none;border:none;text-decoration:none;margin-left:auto;margin-right:auto" width="55" />
                  <p style="font-size:23px;line-height:24px;margin:16px 0;font-weight:600;margin-bottom:0;width:100%;text-align:center;color:#000000">Demo Brand</p>
                </td>
              </tr>
            </tbody>
          </table>
          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="padding:24px">
            <tbody>
              <tr>
                <td>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <h1 style="font-size:29px;line-height:32px;margin:8px 0;color:#000000">Thanks for signing up for the Demo Brand Take Back program!</h1>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <p style="font-size:18px;line-height:23px;margin:16px 0;color:#2c2c2c">All you need to do now is confirm your email address to complete the sign-up process. Just click the button below.</p>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%"><a href="${linkUrl}" style="line-height:100%;text-decoration:none;display:inline-block;max-width:100%;mso-padding-alt:0px;background:#303669;border-radius:100px;color:#fff;padding:12px 24px 12px 24px;font-weight:600;font-size:18px" target="_blank"><span><!--[if mso]><i style="mso-font-width:400%;mso-text-raise:18" hidden>&#8202;&#8202;&#8202;</i><![endif]--></span><span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:9px">Confirm email address</span><span><!--[if mso]><i style="mso-font-width:400%" hidden>&#8202;&#8202;&#8202;&#8203;</i><![endif]--></span></a></tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <hr style="width:100%;border:none;border-top:1px solid #eaeaea;border-color:#DAE3F9" />
          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="padding:24px">
            <tbody>
              <tr>
                <td>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0">For full terms and conditions,<!-- --> <a href="${hostname}/en-US/termsandconditions" style="color:#303669;text-decoration:none" target="_blank">click here</a>.</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0">Please add no-reply@DemoBrandTakeBack.com to your address book. For more information regarding our Privacy Policy, please<!-- --> <a href=href="${hostname}/en-US/privacy" style="color:#303669;text-decoration:none" target="_blank">click here</a>. Please review our<!-- --> <a href=href="${hostname}/en-US/cookies" style="color:#303669;text-decoration:none" target="_blank">cookies policy</a>.</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0">You may<!-- --> <a href="" style="color:#303669;text-decoration:none" target="_blank">change your email preferences</a> <!-- -->or<!-- --> <a href="" style="color:#303669;text-decoration:none" target="_blank">unsubscribe</a> <!-- -->at any time.</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0;margin-bottom:0">©Copyright 2024 Demo Brand.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</html><!--/$-->
`;

export const createdHtml = ({ hostname, userEmail, method }) => `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><!--$-->
<html style="background-color:#FFF" dir="ltr" lang="en">
  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="max-width:37.5em;background-color:#FFFFFF;border:1px solid #DAE3F9;border-radius:4px">
    <tbody>
      <tr style="width:100%">
        <td>
          <head>
            <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
            <meta name="x-apple-disable-message-reformatting" />
            <style>
              @font-face {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                mso-font-alt: 'system-ui';
                src: url(https://fonts.googleapis.com/css2?family=Manrope:wght@400) format('undefined');
              }
              * {
                font-family: 'Manrope', system-ui;
              }
            </style>
          </head>
          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="padding:32px 24px 0;text-align:center">
            <tbody>
              <tr>
                <td><img alt="Demo Brand Logo" height="55" src="${hostname}/emailLogo.jpg" style="display:block;outline:none;border:none;text-decoration:none;margin-left:auto;margin-right:auto" width="55" />
                  <p style="font-size:23px;line-height:24px;margin:16px 0;font-weight:600;margin-bottom:0;width:100%;text-align:center;color:#000000">Demo Brand</p>
                </td>
              </tr>
            </tbody>
          </table>
          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="padding:24px">
            <tbody>
              <tr>
                <td>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <h1 style="font-size:29px;line-height:32px;margin:8px 0;color:#000000">Your trade-in has been started</h1>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <p style="font-size:18px;line-height:23px;margin:16px 0;color:#2c2c2c">Next steps</p>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <td data-id="__react-email-column" style="padding-right:16px;vertical-align:top">
                          <p style="font-size:16px;line-height:24px;margin:16px 0;border-radius:100px;width:24px;height:24px;text-align:center;font-weight:600;background-color:#B84900;color:#FFFFFF">1</p>
                        </td>
                        <td data-id="__react-email-column">
                          <p style="font-size:16px;line-height:24px;margin:8px 0;color:#2c2c2c">Package up your suitcase and make it meets the<!-- --> <a href="${hostname}/en-US/preparation-checklist" style="color:#303669;text-decoration:none" target="_blank">Preparation Checklist</a> <!-- -->requirements.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <td data-id="__react-email-column" style="padding-right:16px;vertical-align:top">
                          <p style="font-size:16px;line-height:24px;margin:16px 0;border-radius:100px;width:24px;height:24px;text-align:center;font-weight:600;background-color:#B84900;color:#FFFFFF">2</p>
                        </td>
                        <td data-id="__react-email-column">
                          <p style="font-size:16px;line-height:24px;margin:8px 0;color:#2c2c2c">${
                            method === "mail"
                              ? `<a href="${hostname}/download-shipping-label" style="color:#303669;text-decoration:none" target="_blank">Download</a> and print your shipping label and attach it to your Take Back package.`
                              : "Visit us in-store at a time that works best for you."
                          }
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <td data-id="__react-email-column" style="padding-right:16px;vertical-align:top">
                          <p style="font-size:16px;line-height:24px;margin:16px 0;border-radius:100px;width:24px;height:24px;text-align:center;font-weight:600;background-color:#B84900;color:#FFFFFF">3</p>
                        </td>
                        <td data-id="__react-email-column">
                          <p style="font-size:16px;line-height:24px;margin:8px 0;color:#2c2c2c">${
                            method === "mail"
                              ? `Take your package to your nearest UPS drop-off point. <a href="https://www.theupsstore.com/tools/find-a-store" style="color:#303669;text-decoration:none" target="_blank">Find your nearest UPS location.</a>`
                              : "Location: DemoBrand Store, 3210 Congress Avenue, Austin, TX 78704"
                          }</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <td data-id="__react-email-column" style="padding-right:16px;vertical-align:top">
                          <p style="font-size:16px;line-height:24px;margin:16px 0;border-radius:100px;width:24px;height:24px;text-align:center;font-weight:600;background-color:#B84900;color:#FFFFFF">4</p>
                        </td>
                        <td data-id="__react-email-column">
                          <p style="font-size:16px;line-height:24px;margin:8px 0 24px;color:#2c2c2c">Wait for your trade-in to be verified before receiving your payment. You can track the status of your trade-in at any time by<!-- --> <a href="${hostname}/en-US/my-trade-ins" style="color:#303669;text-decoration:none" target="_blank">clicking here</a>.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%"><a href="${hostname}/en-US/my-trade-ins" style="line-height:100%;text-decoration:none;display:inline-block;max-width:100%;mso-padding-alt:0px;background:#303669;border-radius:100px;color:#fff;padding:12px 24px 12px 24px;font-weight:600;font-size:18px" target="_blank"><span><!--[if mso]><i style="mso-font-width:400%;mso-text-raise:18" hidden>&#8202;&#8202;&#8202;</i><![endif]--></span><span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:9px">View your trade-in</span><span><!--[if mso]><i style="mso-font-width:400%" hidden>&#8202;&#8202;&#8202;&#8203;</i><![endif]--></span></a></tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <hr style="width:100%;border:none;border-top:1px solid #eaeaea;border-color:#DAE3F9" />
          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="padding:24px">
            <tbody>
              <tr>
                <td>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0;margin-top:0">This email was sent to ${userEmail}</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0">For full terms and conditions,<!-- --> <a href="${hostname}/en-US/termsandconditions" style="color:#303669;text-decoration:none" target="_blank">click here</a>.</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0">Please add no-reply@DemoBrandTakeBack.com to your address book. For more information regarding our Privacy Policy, please<!-- --> <a href=href="${hostname}/en-US/privacy" style="color:#303669;text-decoration:none" target="_blank">click here</a>. Please review our<!-- --> <a href=href="${hostname}/en-US/cookies" style="color:#303669;text-decoration:none" target="_blank">cookies policy</a>.</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0">You may<!-- --> <a href="" style="color:#303669;text-decoration:none" target="_blank">change your email preferences</a> <!-- -->or<!-- --> <a href="" style="color:#303669;text-decoration:none" target="_blank">unsubscribe</a> <!-- -->at any time.</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0;margin-bottom:0">©Copyright 2024 Demo Brand.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</html><!--/$-->
`;

export const verifiedHtml = ({
  hostname,
  userEmail,
  weight,
  value,
  reward,
}) => `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><!--$-->
<html style="background-color:#FFF" dir="ltr" lang="en">
  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="max-width:37.5em;background-color:#FFFFFF;border:1px solid #DAE3F9;border-radius:4px">
    <tbody>
      <tr style="width:100%">
        <td>
          <head>
            <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
            <meta name="x-apple-disable-message-reformatting" />
            <style>
              @font-face {
                font-family: 'Manrope';
                font-style: normal;
                font-weight: 400;
                mso-font-alt: 'system-ui';
                src: url(https://fonts.googleapis.com/css2?family=Manrope:wght@400) format('undefined');
              }
              * {
                font-family: 'Manrope', system-ui;
              }
            </style>
          </head>
          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="padding:32px 24px 0;text-align:center">
            <tbody>
              <tr>
                <td><img alt="Demo Brand Logo" height="55" src="${hostname}/emailLogo.jpg" style="display:block;outline:none;border:none;text-decoration:none;margin-left:auto;margin-right:auto" width="55" />
                  <p style="font-size:23px;line-height:24px;margin:16px 0;font-weight:600;margin-bottom:0;width:100%;text-align:center;color:#000000">Demo Brand</p>
                </td>
              </tr>
            </tbody>
          </table>
          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="padding:24px">
            <tbody>
              <tr>
                <td>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <h1 style="font-size:29px;line-height:32px;margin:8px 0;color:#000000">Your trade-in has been verified</h1>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%">
                        <p style="font-size:18px;line-height:23px;margin:16px 0;color:#2c2c2c">Great news! Your trade-In has been successfully verified.</p>
                        <p style="font-size:18px;line-height:23px;margin:16px 0;color:#2c2c2c">Your receipt is attached. Here are the details of your confirmed trade-in:</p>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="margin-bottom:24px">
                    <tbody>
                      <tr>
                        <td>
                          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="border-bottom:1px solid #DAE3F9">
                            <tbody style="width:100%">
                              <tr style="width:100%">
                                <td data-id="__react-email-column" style="width:33%;padding:8px 0">
                                  <p style="font-size:11px;line-height:24px;margin:0;font-weight:600">WEIGHT</p>
                                </td>
                                <td data-id="__react-email-column" style="width:33%;padding:8px 0">
                                  <p style="font-size:11px;line-height:24px;margin:0;font-weight:600">VERIFIED VALUE</p>
                                </td>
                                <td data-id="__react-email-column" style="width:33%;padding:8px 0">
                                  <p style="font-size:11px;line-height:24px;margin:0;font-weight:600">REWARD</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="border-bottom:1px solid #DAE3F9">
                            <tbody style="width:100%">
                              <tr style="width:100%">
                                <td data-id="__react-email-column" style="width:33%;padding:8px 0">
                                  <p style="font-size:16px;line-height:24px;margin:0;color:#2c2c2c">${weight} lbs</p>
                                </td>
                                <td data-id="__react-email-column" style="width:33%;padding:8px 0">
                                  <p style="font-size:16px;line-height:24px;margin:0;color:#2c2c2c">$${value}</p>
                                </td>
                                <td data-id="__react-email-column" style="width:33%;padding:8px 0">
                                  <p style="font-size:16px;line-height:24px;margin:0;color:#2c2c2c">${reward}</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                    <tbody style="width:100%">
                      <tr style="width:100%"><a href="${hostname}/en-US/my-trade-ins" style="line-height:100%;text-decoration:none;display:inline-block;max-width:100%;mso-padding-alt:0px;background:#303669;border-radius:100px;color:#fff;padding:12px 24px 12px 24px;font-weight:600;font-size:18px" target="_blank"><span><!--[if mso]><i style="mso-font-width:400%;mso-text-raise:18" hidden>&#8202;&#8202;&#8202;</i><![endif]--></span><span style="max-width:100%;display:inline-block;line-height:120%;mso-padding-alt:0px;mso-text-raise:9px">View your trade-in</span><span><!--[if mso]><i style="mso-font-width:400%" hidden>&#8202;&#8202;&#8202;&#8203;</i><![endif]--></span></a></tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <hr style="width:100%;border:none;border-top:1px solid #eaeaea;border-color:#DAE3F9" />
          <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="padding:24px">
            <tbody>
              <tr>
                <td>
                 <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0;margin-top:0">This email was sent to ${userEmail}</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0">For full terms and conditions,<!-- --> <a href="${hostname}/en-US/termsandconditions" style="color:#303669;text-decoration:none" target="_blank">click here</a>.</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0">Please add no-reply@DemoBrandTakeBack.com to your address book. For more information regarding our Privacy Policy, please<!-- --> <a href=href="${hostname}/en-US/privacy" style="color:#303669;text-decoration:none" target="_blank">click here</a>. Please review our<!-- --> <a href=href="${hostname}/en-US/cookies" style="color:#303669;text-decoration:none" target="_blank">cookies policy</a>.</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0">You may<!-- --> <a href="" style="color:#303669;text-decoration:none" target="_blank">change your email preferences</a> <!-- -->or<!-- --> <a href="" style="color:#303669;text-decoration:none" target="_blank">unsubscribe</a> <!-- -->at any time.</p>
                  <p style="font-size:10px;line-height:13px;margin:16px 0;color:#8086A0;margin-bottom:0">©Copyright 2024 Demo Brand.</p>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</html><!--/$-->
`;
