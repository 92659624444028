import * as React from "react";
import { ProfileHead } from "pages/svgs/profile";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const TradeInCard = ({ name, weight, completed }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-start justify-between">
      <div className="flex flex-1 items-start">
        <div className="bg-white px-4 py-4 rounded-lg w-full border-gray-300 border">
          <div className="flex-1 w-full flex">
            <div
              className={`mr-4 my-auto rounded-full px-2 py-1.5 ${
                completed
                  ? "bg-[#C0DFC3] border-green border-2"
                  : "bg-[#E4E4E4]"
              }`}
            >
              <ProfileHead />
            </div>
            <div>
              <div className="font-medium text-[#000000]">{name}</div>
              <div className="text-[#2C2C2C] font-light dark:text-gray-400">
                {weight}{t("scale.weight")} of LEGO Bricks
              </div>
            </div>
            {/* <div className="flex ml-auto mb-auto">
              <DotsButton />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

TradeInCard.propTypes = {
  name: PropTypes.string.isRequired,
  weight: PropTypes.string.isRequired,
  completed: PropTypes.bool,
  email: PropTypes.string,
};

TradeInCard.defaultProps = {
  completed: false,
};

export default TradeInCard;
