import React, { useState } from "react";
import Checklist from "components/checklist";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import { getQueryParams } from "lib/utils";
import { useTranslation } from "react-i18next";
import { useLocation, Link } from "react-router-dom";

export default function StoreChecklist() {
  const { t } = useTranslation();
  const checklistItems = [
    {
      name: "brickBasedToys",
      label:
        "I have only included LEGO bricks, elements, and baseplates that are fully disassembled and in good condition",
    },
    {
      name: "bricksSeparated",
      label:
        "I have ensured all bricks are separated from each other and are not connected",
    },
    {
      name: "removedElectronics",
      label:
        "I have removed all electronic components, batteries and other LEGO branded items such as apparel, key chains, storage containers, building instructions and packaging",
    },
    {
      name: "removedDuplo",
      label: "I have removed any LEGO DUPLO® bricks and elements",
    },
    {
      name: "removedNonLego",
      label: "I have removed any non-LEGO bricks and elements",
    },
  ];

  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  const [allChecked, setAllChecked] = useState(false);

  return (
    <FormContainer
      submitLabel="Continue"
      submitHref={t("urls.signup_store_email")}
      submitParams={queryParams}
      submitDisabled={!allChecked}
    >
      <ProgressBar step={1} stepsNum={3} />
      <div className="flex flex-col gap-large">
        <div className="flex flex-col gap-regular">
          <h2 className="text-primary text-6xl">Preparing Your LEGO® bricks</h2>
          <p className="text-tertiary text-2xl">
            To get started, let's ensure your LEGO bricks are ready to trade in.
          </p>
        </div>
        <div className="flex flex-col gap-x-large">
          <h3 className="text-2xl text-primary font-bold">
            Preparation Checklist
          </h3>
          <Checklist
            setAllChecked={setAllChecked}
            checklistItems={checklistItems}
          />
        </div>
        <div className="mt-8 text-left flex gap-1 items-start">
          <span
            className={`material-symbols-outlined scale-75 text-blue align-bottom block`}
          >
            info
          </span>{" "}
          <div className="text-sm text-gray-500">
            The LEGO® Brick Take Back Program is being operated for and on
            behalf of the LEGO Group by Pentatonic Ltd. See{" "}
            <Link to={t("urls.terms_and_conditions")} className="text-blue">
              Terms & Conditions
            </Link>
            .
          </div>
        </div>
      </div>
    </FormContainer>
  );
}
