import React from "react";
import PropTypes from "prop-types";
import "material-symbols/outlined.css";
import classNames from "classnames";

const SingleCheckbox = ({ label, isLink, link, className, checked, setChecked, disabled }) => {

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <label className={classNames("flex items-center gap-x-3 cursor-pointer", className, disabled && "opacity-[0.7] pointer-events-none")}>
      <div className="relative flex items-center">
        <input
          type="checkbox"
          checked={checked}
          onChange={handleCheckboxChange}
          className="peer appearance-none w-5 h-5 border border-gray-400 rounded-md shrink-0 checked:bg-blue checked:border-0"
        />
        <span className="select-none cursor-pointer material-symbols-outlined absolute shrink-0 text-gray-300 scale-75 -left-0.5 peer-checked:text-white">
          check
        </span>
      </div>
      <span className="text-light">
        {label}{" "}
        {isLink && (
          <span>
            <a href={link} className="text-[#016db8]">
              terms and conditions
            </a>
            <span className="material-symbols-outlined text-[#595f77] align-bottom px-1 scale-75">
              open_in_new
            </span>
          </span>
        )}
      </span>
    </label>
  );
};

SingleCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  isLink: PropTypes.bool,
  link: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
};

SingleCheckbox.defaultProps = {
  isLink: false,
  link: "",
  className: "",
};

export default SingleCheckbox;
