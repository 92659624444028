import React, { useContext, useMemo, useState, useEffect } from "react";
import WeightEstimation from "components/weight-estimation";
import RadioGroup from "components/radio-group";
import { useLocation, useNavigate } from "react-router-dom";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import StoreFormContainer from "components/store-form-container";
import { calculateEstimate } from "lib/calculateEstimate";
import { useTranslation } from "react-i18next";
import { formatEstimationNumber } from "lib/utils";
import AuthContext from "contexts/AuthContext";
import { useTakebackById } from "api/use-takebacks";
import { TAKEBACK_DISBURSEMENT_NAME } from "consts/query-params";
import useWhen from "hooks/useWhen";
import { PAYMENT_ARGS } from "consts/feature-config";

const { REACT_APP_API } = process.env;

const handleInitialFetch = async ({
  id,
  accessToken,
  setIsLoading,
  setTakeback,
}) => {
  try {
    const response = await fetch(`${REACT_APP_API}/takeback/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();

    setTakeback({ paymentType: data.u_lego_payment_type });
  } catch (error) {
    console.error(error);
  }

  setIsLoading(false);
};

export default function StorePayout() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [takeback, setTakeback] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const { t, i18n } = useTranslation();
  const unit = `${t("scale.weight")}`;

  const location = useLocation();
  const queryParams = useMemo(
    () => getQueryParams(location.search),
    [location.search]
  );

  const previouslySetWeight = queryParams.w;

  const estimate = calculateEstimate(
    previouslySetWeight,
    i18n.language.slice(-2)
  );

  const openTakeback = useTakebackById({ id: queryParams.id });

  //only show cash payment in appropriate environments
  const showPayment = useWhen(PAYMENT_ARGS);

  //dynamic radio options depending on dev env
  const radioOptions = [];
  radioOptions.push({
    value: "giftcard",
    title: `${t(`currency.symbol`)}${formatEstimationNumber(estimate.gift)} ${t(
      "store.trade-in-payout.giftcard"
    )}`,
    subtitle: t("store.trade-in-payout.giftcard-subtitle"),
  });
  if (showPayment) {
    radioOptions.push({
      value: "cash",
      title: t("trade-in.payout.payment_title", {
        payment: formatEstimationNumber(estimate.payment),
      }),
      subtitle: t("trade-in.payout.payment_subtitle"),
    });
  }
  radioOptions.push({
    value: "finish",
    title: t("store.trade-in-payout.finish"),
    subtitle: t("store.trade-in-payout.finish-subtitle"),
  });

  // const radioOptions = [
  //   {
  //     value: "giftcard",
  //     title: `${t(`currency.symbol`)}${formatEstimationNumber(
  //       estimate.gift
  //     )} ${t("store.trade-in-payout.giftcard")}`,
  //     subtitle: t("store.trade-in-payout.giftcard-subtitle"),
  //   },
  //   {
  //     value: "finish",
  //     title: t("store.trade-in-payout.finish"),
  //     subtitle: t("store.trade-in-payout.finish-subtitle"),
  //   },
  // ];

  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens.access_token;

  useEffect(() => {
    handleInitialFetch({
      id: queryParams.id,
      accessToken,
      setIsLoading,
      setTakeback,
    });
  }, [accessToken, queryParams]);

  // useEffect(() => {
  //   if (takebackState.u_lego_payment_type) {
  //     setSelectedOption(takebackState.u_lego_payment_type);
  //   }
  // }, [takebackState]);

  console.log(takeback);

  useEffect(() => {
    if (queryParams[TAKEBACK_DISBURSEMENT_NAME]) {
      setSelectedOption(queryParams[TAKEBACK_DISBURSEMENT_NAME]);
    }
  }, [queryParams]);

  useRestartIfAbsent(queryParams, ["w"], "/store/trade-in");

  const updatedParams = {
    ...queryParams,
    w: formatEstimationNumber(previouslySetWeight, 2),
    [TAKEBACK_DISBURSEMENT_NAME]: selectedOption,
  };

  const handleSubmit = async () => {
    if (selectedOption === "finish") {
      navigate(`${t("urls.store_check_in")}`);
    } else {
      const searchParams = new URLSearchParams(updatedParams).toString();
      navigate(`${t("urls.store_payout_confirm")}?${searchParams}`);
    }
  };

  //set selected option to the payment type of the open takeback
  useEffect(() => {
    if (openTakeback) {
      // Ensure openTakeback is available before accessing its properties
      const paymentType = openTakeback.u_lego_payment_type || "";
      setSelectedOption(paymentType);
    }
  }, [openTakeback]);

  if (isLoading || !openTakeback) {
    return null;
  }

  return (
    <StoreFormContainer
      onSubmit={handleSubmit}
      submitLabel="Continue"
      submitDisabled={!selectedOption}
      secondaryLabel="Back"
      secondaryHref={t("urls.store_trade_in")}
      secondaryParams={updatedParams}
    >
      <div className="flex flex-col flex-grow py-4">
        <div className="flex flex-col mr-auto">
          <h1 className="text-6xl font-bold pb-regular">
            Hey, {openTakeback.consumer.firstName}
          </h1>
          <div className="text-tertiary text-2xl">
            Please review the offer for your LEGO® bricks.
          </div>
        </div>
        <WeightEstimation
          className="mt-4"
          singleValue={!showPayment ? estimate.gift : null}
          range={showPayment ? [estimate.payment, estimate.gift] : null}
          description={`Based on ${formatEstimationNumber(
            previouslySetWeight,
            1
          )} ${unit}`}
          animate
        />
        <div>
          <div className="mt-xxx-large text-secondary">
            Confirm your preferred reward option
          </div>
          <RadioGroup
            options={radioOptions}
            name="custom-radio"
            selectedOption={selectedOption}
            onChange={setSelectedOption}
            className="mt-2"
          />
        </div>
      </div>
    </StoreFormContainer>
  );
}
