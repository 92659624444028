import React, { useEffect } from "react";
import KioskStartButton from "../components/start-button";
import FlyingBricks from "../assets/flyingBricks.svg";

export default function PlaceBricks({
  setScreenIndex,
  inputWeight,
  logWeight,
}) {
  // Move to next screen when weight is detected
  useEffect(() => {
    if (inputWeight !== 0) {
      setScreenIndex(3);
    }
  }, [inputWeight, setScreenIndex]);

  return (
    <div
      className="flex flex-col items-center justify-between w-full h-full relative"
      // onClick={() => setScreenIndex(3)}
    >
      <p className="text-[4.5vh] text-center max-w-[50vh] text-white mt-[3vh] px-[4vh]">
        Place your LEGO® bricks into the tray
      </p>
      <KioskStartButton
        className="relative z-50 w-1/4"
        onClick={() => setScreenIndex(0)}
      >
        Cancel
      </KioskStartButton>
      <img
        src={FlyingBricks}
        alt="Simplified depiction of flying LEGO bricks"
        className="absolute bottom-[-10vh] shrink-0"
      />
    </div>
  );
}
