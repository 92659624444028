import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  Fragment,
} from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import logo from "assets/DemoBrandLogo2.jpg";
import AuthContext from "contexts/AuthContext";
import userIcon from "assets/userIcon2.svg";
// import { ProfileHead } from "pages/svgs/profile";
// import { Button } from "components/button";
// import MailIcon from "assets/icons/mail.svg";

import { useTranslation } from "react-i18next";

//import { isAdminEmail } from "consts/user";
import { cn, getQueryParams } from "lib/utils";

export const Header = ({ className, children, showSupport = false }) => {
  const { user, logoutHandler } = useContext(AuthContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const location = useLocation();
  const isStoreView = location.pathname.includes("/store");

  // Get query params
  const queryParams = getQueryParams(location.search);

  const navigate = useNavigate();
  const navigateWithParams = (href) => {
    const navigateOptions = { pathname: href };
    if (queryParams) {
      navigateOptions.search = createSearchParams(queryParams).toString();
    }
    navigate(navigateOptions);
  };

  // const isAdmin = isAdminEmail(user?.email);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    logoutHandler();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="headerPlaceholder sticky w-full h-[71px] md:h-[87px]"></div>
      {/* placeholder above added after making header fixed - to push down page content by height of header */}
      <div
        className={cn(
          "fixed top-0 right-0 w-full bg-opacity-50 flex justify-between items-center p-regular md:p-large border-b border-gray-300 z-40 bg-[#ffffff2a] backdrop-blur-xl",
          className
        )}
      >
        <div
          className="shrink-0 pr-x-large flex items-center gap-4 md:gap-6 cursor-pointer"
          onClick={() =>
            navigateWithParams(isStoreView ? t("urls.store_check_in") : "/")
          }
        >
          <img
            src={logo}
            alt="LEGO Logo"
            className="w-[55px] h-[55px] rounded-md"
          />
          <h1 className="text-xl font-bold md:text-3xl md:font-semibold">
            DemoBrand
          </h1>
        </div>
        <div className="flex gap-1 items-center max-w-fit">
          {children}
          {/* {user && (
            <div className="flex gap-2">
              {isAdmin && isStoreView ? (
                <Button href="/">Seller View</Button>
              ) : (
                isAdmin && (
                  <Button href={t("urls.store_check_in")}>Store View</Button>
                )
              )}
            </div>
          )} */}
          <div className="flex gap-1 whitespace-nowrap">
            {user && (
              <div className="relative">
                <div
                  className="flex gap-2 items-center cursor-pointer px-large"
                  onClick={toggleDropdown}
                >
                  {/* <ProfileHead /> */}
                  <img
                    src={userIcon}
                    alt=""
                    className="w-6 h-6 rounded-full shrink-0 object-cover"
                  />
                  <span className="hidden md:block">{user.email}</span>
                </div>
                {dropdownVisible && (
                  <div
                    ref={dropdownRef}
                    className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg"
                  >
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </div>
            )}
            {/* {showSupport && (
              <div className="flex gap-2 whitespace-nowrap">
                <img
                  src={MailIcon}
                  alt="Mail Icon"
                  className="hidden md:block scale-90"
                />
                <a
                  href="mailto:support@legobricktakeback.com"
                  className="md:hidden"
                >
                  <img src={MailIcon} alt="Mail Icon" className="scale-90" />
                </a>
                <div className="hidden md:block">
                  <Trans
                    i18nKey="ui.header_need_support"
                    components={{
                      1: (
                        <a
                          href="mailto:support@legobricktakeback.com"
                          className="cursor-pointer text-blue"
                        >
                          Contact Support
                        </a>
                      ),
                    }}
                  />
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
