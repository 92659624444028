import { useCallback, useEffect, useState } from "react";
import LegoLogo from "assets/LegoLogo.svg";
import { calculateEstimate } from "lib/calculateEstimate";
import CryptoJS from "crypto-js";

import Start from "./screens/start";
import TermsKiosk from "./screens/terms";
import PlaceBricks from "./screens/place-bricks";
import Scale from "./screens/scale";
import ClearTray from "./screens/clear-tray";
import PlaceMoreBricks from "./screens/place-more-bricks";
import Finish from "./screens/finish";

const { REACT_APP_API } = process.env;
export const SIGNING_KEY = `cdjedlehccbhnbukhtdlrnlifgtdhlnltglifgtdhlctlhuejt`;

export default function Kiosk() {
  const [screenIndex, setScreenIndex] = useState(0);

  //scale input processing
  const [number, setNumber] = useState("");
  const [inputWeight, setInputWeight] = useState(0);
  const [loggedWeights, setLoggedWeights] = useState([]);

  const loggedTotal = loggedWeights.reduce(
    (total, weight) => total + weight,
    0
  );
  const weight = loggedTotal + inputWeight;
  const estimate = calculateEstimate(weight.toFixed(1), "US");

  //final weights for finish screen
  const [finalLoggedWeights, setFinalLoggedWeights] = useState([]);
  const finalWeight = finalLoggedWeights.reduce(
    (total, weight) => total + weight,
    0
  );

  const convertWeight = (weight) => {
    const weightInlbs = weight * 2.20462;
    return weightInlbs;
  };

  useEffect(() => {
    const checkAndReload = () => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      if (
        (currentHour === 6 && currentMinute === 0) ||
        (currentHour === 22 && currentMinute === 0)
      ) {
        window.location.reload();
      }
    };

    const intervalId = setInterval(checkAndReload, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleKeyPress = useCallback(
    (event) => {
      const keyPressed = event.key;
      if (!isNaN(keyPressed) || keyPressed === ".") {
        setNumber((prev) => (prev + keyPressed).slice(0, 6));
      } else if (keyPressed === "Enter") {
        const newWeight = parseFloat(number).toFixed(2);
        setNumber("");

        const weightDifference = Math.abs(newWeight - inputWeight);

        if (!isNaN(newWeight) && weightDifference >= 0.045) {
          const convertedWeight = convertWeight(newWeight);
          setInputWeight(parseFloat(convertedWeight));

          // Send to KV
          const timestamp = new Date().toISOString();
          getLocation().then((location) => {
            sendToKV({ weight: newWeight, location, timestamp });
          });
        }
      }
    },
    [number, inputWeight]
  );

  const getLocation = async () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const sendToKV = async (data) => {
    try {
      await fetch(`${REACT_APP_API}/audit/scale`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("Error sending data to KV:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      handleKeyPress(event);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyPress]);

  const signedWeight = CryptoJS.SHA256(
    `${finalWeight.toFixed(1)}_${SIGNING_KEY}`
  ).toString(CryptoJS.enc.Hex);

  const logWeight = () => {
    if (inputWeight !== 0) {
      setLoggedWeights([...loggedWeights, inputWeight]);
    }
  };

  const removeLastLoggedWeight = () => {
    const newLoggedWeights = [...loggedWeights];
    newLoggedWeights.pop();
    setLoggedWeights(newLoggedWeights);
  };

  const logFinalWeights = () => {
    setFinalLoggedWeights([...loggedWeights, inputWeight]);
  };

  const [bgColorScale, setBgColorScale] = useState(false);

  //screen map as "mini router"
  const screenMap = {
    0: {
      element: (
        <Start
          setScreenIndex={setScreenIndex}
          setLoggedWeights={setLoggedWeights}
          loggedWeights={loggedWeights}
        />
      ),
      bgColor: "yellow-transient",
    },
    1: {
      element: (
        <TermsKiosk
          setScreenIndex={setScreenIndex}
          setInputWeight={setInputWeight}
        />
      ),
      bgColor: "green",
    },
    2: {
      element: (
        <PlaceBricks
          setScreenIndex={setScreenIndex}
          inputWeight={inputWeight}
          logWeight={logWeight}
        />
      ),
      bgColor: "blue",
    },
    3: {
      element: (
        <Scale
          setScreenIndex={setScreenIndex}
          weight={weight}
          estimate={estimate}
          logWeight={logWeight}
          inputWeight={inputWeight}
          logFinalWeights={logFinalWeights}
          loggedWeights={loggedWeights}
          removeLastLoggedWeight={removeLastLoggedWeight}
          setInputWeight={setInputWeight}
          bgColorScale={bgColorScale}
          setBgColorScale={setBgColorScale}
        />
      ),
      bgColor: !bgColorScale ? "blue-200" : "blue",
    },
    4: {
      element: (
        <ClearTray
          setScreenIndex={setScreenIndex}
          inputWeight={inputWeight}
          setInputWeight={setInputWeight}
        />
      ),
      bgColor: "blue",
    },
    5: {
      element: (
        <PlaceMoreBricks
          setScreenIndex={setScreenIndex}
          loggedWeight={loggedTotal}
          removeLastLoggedWeight={removeLastLoggedWeight}
          logWeight={logWeight}
          setInputWeight={setInputWeight}
        />
      ),
      bgColor: "blue",
    },
    6: {
      element: (
        <Finish
          setScreenIndex={setScreenIndex}
          weight={finalWeight}
          estimate={estimate}
          loggedWeights={finalLoggedWeights}
          signedWeight={signedWeight}
        />
      ),
      bgColor: "blue-200",
    },
  };

  //vh used as unit for font size and spacing to ensure consistent scaling across portrait and landscape orientations
  return (
    <div
      className={`w-screen h-screen ${
        screenMap[screenIndex].bgColor === "blue-200"
          ? "bg-[#e2f3ff]"
          : screenMap[screenIndex].bgColor === "yellow-transient"
          ? "bg-gradient-to-b from-[#FFF087] via-[#FFE953] to-[#FFE21A]"
          : "bg-" + screenMap[screenIndex].bgColor
      } flex flex-col items-center overflow-hidden font-bold`}
    >
      {/* the below width allows some expansion for screens wider than 9:16 but optimises for portrait ratio */}
      <div className="w-[min(65vh,100vw)] h-full py-[4vh] flex flex-col items-center relative">
        <img
          src={LegoLogo}
          alt="Lego logo"
          className="w-[8vh] mt-[2vh] relative z-10"
        />
        {screenMap[screenIndex].element}
      </div>
    </div>
  );
}
