import { Fragment } from "react";

export default function InputValidation({
  errorMessage,
  fontSize = "text-sm",
  className = "",
}) {
  return (
    <Fragment>
      {errorMessage ? (
        <div className={className}>
          <div
            className={`text-secondary ${fontSize} mt-regular flex content-center gap-xx-small w-full items-start`}
          >
            <span
              className={`material-symbols-outlined scale-75 text-red align-bottom block`}
            >
              error
            </span>{" "}
            <div
              className="w-[calc(100%-20px)]"
              style={{
                "whiteSpace": "preWrap",
                "wordWrap": "breakWord",
              }}
            >
              {errorMessage}
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}
