// import classNames from "classnames";
import { Trans, useTranslation } from "react-i18next";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import Logo from "assets/DemoBrandLogo2.jpg";
import LocationIcon from "assets/icons/location.svg";
import { getQueryParams, useCurrentLocation } from "lib/utils";
import { COUNTRY_NAME } from "consts/query-params";

const LocationButton = ({ setModalOpen }) => {
  const { location } = useCurrentLocation();

  return (
    <div
      onClick={() => setModalOpen(true)}
      className="rounded-full cursor-pointer bg-[#0000008e] hover:bg-[#000000b1] select-none inline-flex items-center justify-center whitespace-nowrap font-medium px-x-large py-regular leading-7"
    >
      <img src={LocationIcon} alt="Location Icon" className="mr-2 -ml-1" />
      {location.name}
    </div>
  );
};

export const Footer = ({ children, className }) => {
  // translation
  const { t } = useTranslation();

  const { location: currentLocation } = useCurrentLocation();

  const { setShowLocationModal } = useOutletContext();

  const showImpressum =
    currentLocation && currentLocation[COUNTRY_NAME] === "DE";

  // Get query params
  const location = useLocation();
  const queryParams = getQueryParams(location.search);

  const navigate = useNavigate();

  const navigateWithParams = (href) => {
    const navigateOptions = { pathname: href };
    if (queryParams) {
      navigateOptions.search = createSearchParams(queryParams).toString();
    }
    navigate(navigateOptions);
  };

  return (
    <footer className="bg-[#2c3160] px-xxx-large py-xxx-large text-white">
      {/* on mobile */}
      <div className="flex w-full justify-between md:hidden mb-xxx-large">
        <img src={Logo} alt="logo" className="w-12" />
        <LocationButton setModalOpen={setShowLocationModal} />
      </div>
      {/* on desktop */}
      <div className="flex justify-between">
        <div className="flex flex-col md:flex-row gap-xxx-large md:gap-xxxx-large">
          <img
            src={Logo}
            alt="LEGO Logo"
            className="w-[80px] h-[80px] hidden md:block rounded-md"
          />
          <div className="flex flex-col gap-large">
            <div className="uppercase">{t("footer.information")}</div>
            <div className="flex flex-col gap-medium text-gray-300">
              <div
                onClick={() =>
                  navigateWithParams(t("urls.preparation_checklist"))
                }
                className="hover:underline cursor-pointer"
              >
                {t("footer.checklist")}
              </div>
              <div
                onClick={() =>
                  navigateWithParams(t("urls.terms_and_conditions"))
                }
                className="hover:underline cursor-pointer"
              >
                {t("footer.terms")}
              </div>
              <div
                onClick={() => navigateWithParams(t("urls.privacy_policy"))}
                className="hover:underline cursor-pointer"
              >
                {t("footer.privacy")}
              </div>
              <div
                onClick={() => navigateWithParams(t("urls.cookie_policy"))}
                className="hover:underline cursor-pointer"
              >
                {t("footer.cookie")}
              </div>
              {showImpressum ? (
                <a
                  href={t("urls.impressum")}
                  title="Impressum"
                  className="hover:underline cursor-pointer"
                >
                  Impressum
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <div className="flex flex-col gap-large">
            <div className="uppercase">{t("footer.about_us")}</div>
            <div className="flex flex-col gap-medium text-gray-300">
              <a
                href={t("footer.lego_link")}
                className="hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.lego_homepage")}
              </a>
              <a
                href="https://lego.com/sustainability"
                className="hover:underline"
                target="_blank"
                rel="noreferrer"
              >
                {t("footer.sustainability")}
              </a>
            </div>
          </div> */}
        </div>
        <div className="hidden md:block">
          <LocationButton setModalOpen={setShowLocationModal} />
        </div>
      </div>
      <div className="text-sm font-light mt-xxx-large">
        <p className="mt-small">
          <Trans
            i18nKey={"footer.legal"}
            components={{
              1: (
                <Link
                  to={t("urls.terms_and_conditions")}
                  className="cursor-pointer underline"
                  target="_blank"
                  rel="noopener noreferrer"
                />
              ),
            }}
          />
        </p>
      </div>
    </footer>
  );
};
