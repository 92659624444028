import { useEffect, useRef } from "react";
import FormContainer from "components/form-container";
import ProgressBar from "components/progress-bar";
import {
  createSearchParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { getQueryParams } from "lib/utils";
import { useRestartIfAbsent } from "hooks/useRestartIfAbsent";
import { useTranslation, Trans } from "react-i18next";
import { TAKEBACK_METHOD_NAME } from "consts/query-params";
import { Button } from "components/button";
import { useSendEmailUpdate } from "api/use-email";

export default function VerificationPage() {
  const { t } = useTranslation();
  const emailInput = localStorage.getItem("emailInput");

  const { mutate: sendSignUpEmail } = useSendEmailUpdate();

  // Get query params and restart flow if absent
  const location = useLocation();
  const queryParams = getQueryParams(location.search);
  useRestartIfAbsent(
    queryParams,
    ["w", TAKEBACK_METHOD_NAME, "p"],
    t("urls.trade_in_weight")
  );
  const resendSuccessful = useRef(false);

  // reset right side visual
  const { setSidebarVisual } = useOutletContext();
  useEffect(() => {
    if (queryParams[TAKEBACK_METHOD_NAME] === "mail") {
      setSidebarVisual("suitcase");
    } else {
      setSidebarVisual("suitcaseStore");
    }
  }, [queryParams, setSidebarVisual]);

  const params = queryParams
    ? createSearchParams(queryParams).toString()
    : "";

  const handleResendEmail = () => {
    sendSignUpEmail({
      emailType: `signUp`, userEmail: emailInput, emailLinkUrl: `${window.location.origin}${t(
        `urls.trade_in_sign_in`
      )}?${params}`
    },
      {
        onSuccess: () => {
          resendSuccessful.current = true;
        },
      }
    )
  };

  return (
    <FormContainer>
      <ProgressBar step={5} />
      <div className="flex flex-col gap-regular">
        <h2 className="font-semibold text-6xl max-w-lg">
          {t("trade-in.verification.title")}
        </h2>
        <p className="text-[#7E7D7D] text-2xl leading-normal font-light">
          <Trans
            i18nKey="trade-in.verification.description"
            components={{ email: emailInput }}
          />
        </p>
        <Button
          variant="default"
          type="button"
          className="w-full md:w-1/2 mx-auto md:ml-0 mt-8"
          onClick={handleResendEmail}
        >
          {t("trade-in.verification.resend")}
        </Button>
        {resendSuccessful.current && (
          <div className="mt-4">
            <span className="font-normal text-[#4bae55] text-2xl">
              Email resent successfuly. Please check your inbox.
            </span>
          </div>
        )}
      </div>
    </FormContainer>
  );
}
