import React from "react";
import KioskStartButton from "../components/start-button";
import QRCode from "react-qr-code";
import { motion } from "framer-motion";

import KioskBg from "../assets/kiosk-bg.png";

export default function Finish({
  setScreenIndex,
  weight,
  estimate,
  loggedWeights,
  signedWeight,
}) {
  const handleFinish = () => {
    localStorage.removeItem("hasAnimated");
    setScreenIndex(0);
  };
  return (
    <div className="flex flex-col w-full h-screen relative">
      <div className="flex-grow overflow-auto">
        <div className="flex flex-col px-[4vh] relative z-10 items-center">
          <p className="text-[90px] text-center max-w-[1156px] text-black mt-[3vh] px-[4vh]">
            Your trade-in <br></br>weighing summary
          </p>
          <div className="flex w-full pl-[110px] justify-center items-baseline mt-2vh">
            <div className="text-[252px]">{weight.toFixed(1)}</div>
            <div className="text-[69px] ml-[0.5vh]">lbs</div>
          </div>
          <div className="flex w-full justify-center gap-[4vh]">
            <div className="flex flex-col items-center">
              <div className="flex items-top">
                <div className="text-[1.5vh] mr-[1rem] mt-[1vh]">$</div>
                <div className="text-[90px]">{estimate.gift}</div>
              </div>
              <div
                style={{ fontWeight: 400 }}
                className="text-[40px] w-[16vh] text-center text-black-400"
              >
                As a LEGO e-Gift Card
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="flex items-top">
                <div className="text-[1.5vh] mr-[1rem] mt-[1vh]">$</div>
                <div className="text-[90px]">{estimate.payment}</div>
              </div>
              <div
                style={{ fontWeight: 400 }}
                className="text-[40px] w-[16vh] text-center text-black-400"
              >
                As Digital Cash{" "}
              </div>
            </div>
          </div>
          {/* <div className="flex items-baseline rounded-2xl bg-white/50 gap-6 px-[4vh] mt-[3vh]">
              <span
                className={`material-symbols-outlined scale-150 text-blue align-bottom block`}
              >
                info
              </span>{" "}
              <p className="text-[50px] text-center text-black">
                For brick specialist use only
              </p>
            </div> */}
        </div>
        {loggedWeights.length > 1 && (
          <div className="flex flex-col w-[1156px] relative z-10 mx-auto mt-[4vh] max-h-[800px] overflow-scroll">
            {loggedWeights.map((weight, index) => (
              <div
                key={index}
                className={`flex justify-between font-medium w-full text-[40px] bg-white/50 p-[24px] ${
                  index === loggedWeights.length - 1
                    ? ""
                    : "border-b-[1px] border-gray"
                } border-black`}
              >
                <div>Tray {index + 1}</div>
                <div className="">
                  {weight.toFixed(1)}
                  <span className="text-[40px]"> lbs</span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-row gap-[48px] justify-center items-center w-full px-[4vh] relative z-10 h-[200px]">
        <KioskStartButton
          className="w-1/3"
          onClick={() => {
            setScreenIndex(3);
          }}
          icon="arrow_back"
        >
          Back
        </KioskStartButton>
        <div className="w-[232px] h-[260px] shadow-md flex flex-col bg-white rounded-xl relative">
          <QRCode
            value={`${weight.toFixed(1)}_${signedWeight}`}
            size={128}
            className="relative w-full h-full p-[14px]"
          />
          <p className="text-[14px] text-center text-black pb-4">
            For brick specialist use only
          </p>
        </div>
        <KioskStartButton
          className="w-1/3 font-bold"
          onClick={() => {
            handleFinish();
          }}
          icon="check"
          iconColor="black"
          color="yellow"
        >
          Finish
        </KioskStartButton>
      </div>
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        src={KioskBg}
        alt=""
        className="absolute bottom-[-120px] w-full left-0 object-cover"
      />
    </div>
  );
}
