export const ENV_LOCAL = `local`;
export const ENV_BRANCH = `branch`;
export const ENV_NEXT = `next`;
export const ENV_PRODUCTION = `production`;

// const simulatedEnvs = {
//   [ENV_LOCAL]: `localhost`,
//   [ENV_BRANCH]: `simulated.lego-takeback-clients.pages.dev`,
//   [ENV_NEXT]: `next.legobricktakeback.com`,
//   [ENV_PRODUCTION]: `multi-commerce-demo.pages.dev`,
// };

// const envs = {
//   [ENV_LOCAL]: /localhost/,
//   [ENV_BRANCH]: /.*\.lego-takeback-clients\.pages\.dev/,
//   [ENV_NEXT]: /next\.legobricktakeback\.com/,
//   [ENV_PRODUCTION]: /multi-commerce-demo\.pages\.dev/,
// };

const useWhen = ({ env = [] }) => {
  // const params = Object.fromEntries(
  //   new URLSearchParams(window.location.search)
  // );
  // const hostname = params.simulateEnv
  //   ? simulatedEnvs[params.simulateEnv]
  //   : window.location.hostname;

  return true;
};

export default useWhen;
