import React, { createContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    try {
      const storedUser = localStorage.getItem("user");
      return storedUser ? JSON.parse(storedUser) : null;
    } catch (error) {
      console.error("Error loading user from localStorage", error);
      return null;
    }
  });

  const loginHandler = (
    { username, password },
    { onSuccess = () => { } } = {},
    { onError = () => { } } = {},
  ) => {
    setTimeout(() => {
      try {
        // Mock authentication logic: Accept any username and password
        const mockUser = { email: username };
        localStorage.setItem("user", JSON.stringify(mockUser));
        setUser(mockUser);
        onSuccess(mockUser);
      } catch (error) {
        console.error("Error during login", error);
        onError(error);
      }
    }, 1000); // 1-second delay to simulate backend response
  };

  const logoutHandler = (redirectTo = "/") => {
    try {
      localStorage.removeItem("user");
      setUser(null);
      window.location.href = "/";
    } catch (error) {
      console.error("Error during logout", error);
    }
  };

  // Empty functions and variables
  const signupHandler = (_, { onSuccess }) => {
    setTimeout(onSuccess, 1500);
  };
  const resendEmailVerificationHandler = () => { };
  const loginError = null;
  const signupError = null;
  const resendEmailError = null;
  const authTokens = null;

  return (
    <AuthContext.Provider
      value={{
        user,
        authTokens,
        logoutHandler,
        loginHandler,
        loginError,
        signupHandler,
        signupError,
        resendEmailVerificationHandler,
        resendEmailError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
