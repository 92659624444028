import { cn } from "lib/utils";
import React from "react";

export default function KioskButton({
  children,
  className,
  onClick = () => {},
  disabled = false,
}) {
  return (
    <div
      className={cn(
        `bg-white text-[2.2vh] px-[2vh] py-[1.5vh] rounded-[1.5vh] flex items-center justify-center cursor-pointer text-center  active:scale-95 active:bg-[#f4f4f4] transition transition-1 ${
          disabled && "opacity-50"
        }`,
        className
      )}
      style={{ filter: "drop-shadow(0 25px 25px rgb(0 0 0 / 0.08))" }}
      onClick={!disabled ? onClick : () => {}}
    >
      {children}
    </div>
  );
}
