import PropTypes from "prop-types";
import classNames from "classnames";

const WeightInput = ({
  className,
  placeholder = "0",
  caption = "Estimated Weight",
  unit,
  value,
  locale = "en-US",
  onChange = () => {},
}) => {
  const handleInput = (e) => {
    let inputValue = e.target.value;

    if (locale !== "en-US") {
      inputValue = inputValue.replace(/[^\d,]/g, "");
      const parts = inputValue.split(",");

      if (parts[1]) {
        inputValue = `${parts[0]},${parts[1].slice(0, 1)}`;
      }

      const numericValue = parseFloat(inputValue.replace(",", "."));
      if (!isNaN(numericValue) && numericValue >= 0) {
        e.target.value = inputValue;
      } else if (inputValue === "," || inputValue === "") {
        e.target.value = inputValue;
      } else {
        e.target.value = "";
      }

      onChange({
        ...e,
        target: {
          ...e.target,
          value: inputValue.replace(",", ".")
        }
      });
    } else {
      inputValue = inputValue.replace(/[^\d.]/g, "");
      const parts = inputValue.split(".");

      if (parts[1]) {
        inputValue = `${parts[0]}.${parts[1].slice(0, 1)}`;
      }

      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue) && numericValue >= 0) {
        e.target.value = inputValue;
      } else if (inputValue === "." || inputValue === "") {
        e.target.value = inputValue;
      } else {
        e.target.value = "";
      }

      onChange(e);
    }
  };

  return (
    <div className={classNames(className)}>
      <label htmlFor="weight">
        <div className="mb-2 block">{caption}</div>
        <div className="flex items-center rounded-lg border border-gray-400 overflow-hidden">
          <input
            type="text"
            inputMode="decimal"
            className="flex-1 min-w-0 outline-none text-light text-primary h-12 px-3 py-2 placeholder-gray-300"
            placeholder={placeholder}
            value={locale !== "en-US" ? value?.toString().replace(".", ",") : value}
            onChange={onChange}
            onInput={handleInput}
            name="weight"
            lang={locale}
          />
          <div className="ml-2 text-secondary px-2 bg-gray-400 shrink-0 h-12 flex items-center">
            {unit === "kg" ? "kg" : "pounds"}
          </div>
        </div>
      </label>
    </div>
  );
};

WeightInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  caption: PropTypes.string,
  unit: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default WeightInput;
