import AuthContext from "contexts/AuthContext";
import { useEffect, useContext, useState } from "react";
import { useMutation, useQuery } from "react-query";

const { REACT_APP_API } = process.env;

const fetchTakebacks = async ({ accessToken }) => {
  const response = await fetch(`${REACT_APP_API}/takeback/retrieve-takeback`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch takebacks");
  }

  return response.json();
};

const createTakeback = async ({ username, accessToken, payload }) => {
  const response = await fetch(`${REACT_APP_API}/takeback/create-takeback`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      username,
      weight: payload.weight,
      u_lego_postsort_weight: payload.u_lego_postsort_weight,
      u_lego_value_currency: payload.u_lego_value_currency,
      uom: payload.uom,
      ...(payload.paymentType ? { paymentType: payload.paymentType } : {}),
      returnMethod: payload.returnMethod,
      store: payload.store,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to create takeback");
  }

  return response.json();
};

const updateTakeback = async ({ accessToken, payload }) => {
  const response = await fetch(`${REACT_APP_API}/takeback/update-takeback`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      ...payload,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to update takeback");
  }

  return response.json();
};

export const useUpdateTakeback = ({ onSuccess = () => ({}) } = {}) => {
  const { user, authTokens } = useContext(AuthContext);
  const username = user?.email;
  const accessToken = authTokens?.access_token;

  return useMutation({
    mutationKey: ["update-takebacks", username, accessToken],
    mutationFn: (payload) =>
      updateTakeback({
        accessToken,
        payload,
      }),
    onSuccess,
  });
};

export const useCreateTakeback = ({ onCreate }) => {
  const { user, authTokens } = useContext(AuthContext);
  const username = user.email;
  const accessToken = authTokens?.access_token;

  return useMutation({
    mutationKey: ["create-takebacks", username, accessToken],
    mutationFn: async (payload) =>
      createTakeback({
        username,
        accessToken,
        payload,
      }),
    onSuccess: onCreate,
  });
};

export const useMyTakebacks = () => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  return useQuery(
    ["takebacks", accessToken],
    () =>
      fetchTakebacks({
        accessToken,
      }),
    {
      enabled: !!accessToken,
    },
  );
};

const fetchTakebackById = async ({ id, accessToken, setTakeback }) => {
  const response = await fetch(`${REACT_APP_API}/takeback/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch takeback");
  }

  setTakeback(await response.json());
};

export const fetchTakebacksByTracking = async ({
  accessToken,
  upsTracking,
  dhlTracking,
}) => {
  const params = new URLSearchParams();
  if (upsTracking) params.append("ups_tracking", upsTracking);
  if (dhlTracking) params.append("dhl_tracking", dhlTracking);

  const response = await fetch(
    `${REACT_APP_API}/warehouse/get-mail-in?${params.toString()}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to fetch takeback");
  }

  return response.json();
};

const fetchTakebacksByShipping = async ({ accessToken, shippingCode }) => {
  const response = await fetch(`${REACT_APP_API}/shipping/${shippingCode}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to get shipping");
  }

  return response.json();
};

export const useTakebackByShippingTracking = ({
  upsTracking,
  dhlTracking,
  shippingCode,
  onSuccess,
}) => {
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  return useQuery(
    ["takebackByTracking", upsTracking, dhlTracking, shippingCode],
    () => {
      if (shippingCode) {
        return fetchTakebacksByShipping({
          accessToken,
          shippingCode,
        });
      }
      return fetchTakebacksByTracking({
        accessToken,
        upsTracking,
        dhlTracking,
      });
    },
    {
      enabled: Boolean(
        !!accessToken && (shippingCode || upsTracking || dhlTracking),
      ),
      onSuccess,
    },
  );
};

const fetchTakebacksByEmail = async ({ email, accessToken, setTakebacks }) => {
  const response = await fetch(`${REACT_APP_API}/takeback/${email}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch takeback");
  }

  setTakebacks(await response.json());
};

export const useTakebackById = ({ id }) => {
  const [takeback, setTakeback] = useState();
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  useEffect(() => {
    fetchTakebackById({ id, accessToken, setTakeback });
  }, [accessToken, id]);

  return takeback?.[0];
};

export const useTakebacksByEmail = ({ email }) => {
  const [takebacks, setTakebacks] = useState();
  const { authTokens } = useContext(AuthContext);
  const accessToken = authTokens?.access_token;

  useEffect(() => {
    fetchTakebacksByEmail({ email, accessToken, setTakebacks });
  }, [accessToken, email]);

  return takebacks;
};
