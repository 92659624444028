import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Header } from "components/header";
import AuthContext from "contexts/AuthContext";
import { Button } from "components/button";
import { LOCALE_NAME, STATE_NAME, COUNTRY_NAME } from "consts/query-params";
import { useCurrentLocation } from "lib/utils";
// import { Link } from "react-router-dom";
import ScrollToTop from "components/scroll-to-top";
import { Footer } from "components/footer/footer";

// import ImpressumFooter from "components/footer-impressum";

export default function CookiePolicyPage() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);

  const { location: currentLocation } = useCurrentLocation();

  const CtaButton = ({ className }) => {
    return (
      <Button
        href="/trade-in-weight"
        queryParams={{
          [LOCALE_NAME]: currentLocation.locale,
          [STATE_NAME]: currentLocation.state,
          [COUNTRY_NAME]: currentLocation.country,
        }}
        className={className}
      >
        {t("landing.button_start_new")}
      </Button>
    );
  };

  return (
    <div className="min-h-screen w-screen flex flex-col justify-between">
      <Header classNames="bg-yellow border-none">
        <div className="flex gap-large">
          <CtaButton className="hidden md:block" />
          <Button
            href={user ? t("urls.my_trade_ins") : t("urls.login")}
            variant="secondary"
          >
            {user
              ? t("landing.button_my_trade_ins")
              : t("landing.button_sign_in")}
          </Button>
        </div>
      </Header>
      <ScrollToTop />
      <div className="w-full px-8 container mx-auto mt-16 mb-16">
        <div className="max-w-prose mx-auto">
          <h1 className="text-3xl font-bold mb-8">Cookie Policy</h1>

          <h1 className="mb-4">This is where your cookie policy goes.</h1>
          <p className="text-black-300">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni
            architecto neque praesentium facilis nihil atque at dignissimos
            repudiandae esse. Suscipit eaque expedita rem esse molestias
            nesciunt voluptate illo consectetur nemo. Obcaecati repudiandae unde
            necessitatibus, rerum suscipit incidunt illo quasi iusto inventore
            officia quidem cum amet voluptates odio numquam dicta, provident
            beatae? Aliquam alias odit labore facere accusamus maiores autem
            mollitia!
          </p>
        </div>
      </div>
      <Footer />
      {/* <ImpressumFooter /> */}
    </div>
  );
}
