import React from "react";
import PropTypes from "prop-types";
//import { useTranslation } from "react-i18next";

function ProgressBar({ step, stepsNum = 8 }) {
  const totalSteps = stepsNum;
  const width = `${(step / totalSteps) * 100}%`;

  //const { t } = useTranslation();

  return (
    <div className="w-full">
      <div className="text-sm uppercase mb-2">
        {/* {t("ui.progressBar")} {step} */}
      </div>
      <div className="h-[5px] bg-gray-300 w-full relative rounded-full">
        <div
          className={`absolute top-0 left-0 h-full bg-[#2a2a2a] z-10 rounded-full`}
          style={{ width }}
        ></div>
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
  stepsNum: PropTypes.number.isRequired,
};

export default ProgressBar;
