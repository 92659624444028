import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ForgotPasswordLink = ({ returnToPath }) => {
  const { t } = useTranslation();

  return <Link>{t(`ui.link_forgot_password`)}</Link>;
};

export default ForgotPasswordLink;
