export const calculateEstimate = (weight, country = "US") => {
  let payment, gift;
  if (country === "US") {
    payment = (weight * 2.40).toFixed(2);
    gift = (weight * 4).toFixed(2);
  } else {
    payment = (weight * 4.80).toFixed(2);
    gift = (weight * 8).toFixed(2);
  }
  return { gift, payment };
};
